import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { environment } from './../environments/environment';
import { MyserviceService } from './myservice.service';
import { SEND_EMAIL_BYJSON } from './interface';
import { literal } from '@angular/compiler/src/output/output_ast';

import { DOCUMENT } from '@angular/common';
import { url } from 'inspector';

interface login_data {
	email: string;
	password: string;
	login_method: string;
}

@Injectable({
	providedIn: 'root'
})

export class ApiService {
	// base_url: string = this._MyserviceService.get_API_End_Point();
	//base_url: string = environment.apiUrl;
	base_url: string = 'https://desktopio.smarter-biz.com';
	// base_url: string = 'http://localhost:3000'
	// base_url: string = '';
	// base_url: string ='https://sharekhan.youearn.in';
	// 'https://security.youearn.in';
	//base_url: string = 'https://web.youearn.in';
	// base_url_chat: string = 'http://3.6.66.60:3025';
	base_url_ainex: string = 'https://ainex.contiinex.com';
	base_url_comnex: string = 'https://www.contiinex.com';
	base_url_uearn: string = 'https://uearn.ai';
	base_url_chat: string = 'https://devchat.contiinex.com';

	// base_url:string = 'http://beta.youearn.in';
	//base_url:string = 'http://3.6.155.59:4000';
	base_url_version: string = '/api/v1';
	base_url_version_mobile: string = '/api/v2';

	offset: number = 330;

	_selectClient: any;
	_editBatchObj: any;
	batchidTraining: any;
	meetingLoacationLink: any;
	_countLeadsConnected: any;
	_countfollowupTodo: any;
	constructor(
		private http: HttpClient,
		@Inject(DOCUMENT) document,
		private injector: Injector,
		// private _service:MyserviceService
	) {
	}

	getIpAddress(){
		return this.http.get("https://api.ipify.org/?format=json&callback=JSONP_CALLBACK")
	}
	// Login Api
	SBIloginAPI(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/auth?email=pm@sbicollection.com&password=q&login_method=smb
		var url = "https://login.contiinex.com/auth?source_of_login=smb&new_login=true&";
		//console.log(url)
		url += "email=" + obj.email + "&password=" + obj.password + "&login_method=" + obj.login_method;
		if(obj.otp_number){
			url += "&otp_number=" + obj.otp_number
		}
		// return this.http.get(url, { observe: 'response' }); // to get the response with headers
		return this.http.get(url);
	}
	// Dev Api
	SBIDEVloginAPI(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/auth?email=pm@sbicollection.com&password=q&login_method=smb
		var url = "https://dev.contiinex.com/auth?source_of_login=smb&new_login=true&";
		//console.log(url)
		url += "email=" + obj.email + "&password=" + obj.password + "&login_method=" + obj.login_method
		if(obj.otp_number){
			url += "&otp_number=" + obj.otp_number
		}
		// return this.http.get(url, { observe: 'response' }); // to get the response with headers
		return this.http.get(url);
	}
	// Login Api
	sbiotploginAPI(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/auth?email=pm@sbicollection.com&password=q&login_method=smb
		var url = "https://login.contiinex.com/auth?source_of_login=smb&new_login=true&";
		//var url = "https://dev.smarter-biz.com/auth?source_of_login=WEB&";
		//console.log(url)
		url += "email=" + obj.email + "&password=" + obj.password + "&login_method=" + obj.login_method + "&otp_number=" + obj.otp_number;
		// return this.http.get(url, { observe: 'response' }); // to get the response with headers
		return this.http.get(url);
	}
	// Login Api
	loginAPI(obj: login_data) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/auth?email=pm@sbicollection.com&password=q&login_method=smb
		var url = this.base_url + "/auth?source_of_login=WEB&";
		//console.log(url)
		url += "email=" + obj.email + "&password=" + obj.password + "&login_method=" + obj.login_method
		// return this.http.get(url, { observe: 'response' }); // to get the response with headers
		return this.http.get(url);
	}
	loginAPIApp(obj: login_data) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/auth?email=pm@sbicollection.com&password=q&login_method=smb
		var url = this.base_url + "/check_login.json?";
		//console.log(url);
		url += "email=" + obj.email + "&password=" + obj.password + "&login_method=" + obj.login_method + "&ver=3&source_of_login=WEB&device_info=WEB"
		// return this.http.get(url, { observe: 'response' }); // to get the response with headers
		return this.http.get(url);
	}
	loginAPIComnex(obj: login_data) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/auth?email=pm@sbicollection.com&password=q&login_method=smb
		var url = this.base_url_comnex + "/auth?source_of_login=WEB&";
		//console.log(url)
		url += "email=" + obj.email + "&password=" + obj.password + "&login_method=" + obj.login_method
		// return this.http.get(url, { observe: 'response' }); // to get the response with headers
		return this.http.get(url);
	}
	// Team Api
	teamAPI(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//this.base_url= this._service.get_API_End_Point();
		//console.log(this.base_url);
		// GET API - https://youearn.in/api/v1/team
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/team";
		//console.log('teamAPI',url);
		return this.http.get(url);
	}

	/* Getting details of the superviors to the users in the team */
	getSupervisors(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/getUsersWithSupervisors
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/getUsersWithSupervisors";
		return this.http.get(url);
	}
	/* Getting details of the superviors to the users in the team for particular group*/
	getSupervisors_for_group(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/getUsersWithSupervisors
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + `/getUsersWithSupervisors?for_group=${obj.for_group}`;
		return this.http.get(url);
	}
	// Settings API
	settingsAPI(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//this.base_url= this._service.get_API_End_Point();
		// GET API - https://youearn.in/api/v1/settings/stages
		let api = "/settings/stages";
		// let url = this.base_url + this.base_url_version + api;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		//console.log('settingsAPI',url);
		return this.http.get(url);
	}
	// Full Team API
	getFullTeamData(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//this.base_url= this._service.get_API_End_Point();
		// GET API - https://youearn.in/api/v1/full_group_details
		let api = "/full_group_details";
		//let url = this.base_url + this.base_url_version + api;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	/*********  Recruiter and Campaign Dashboard APIs starts  *************/

	// Get Applicant Dat API
	getApplicantData(startDate, endDate, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/recruitment/getAllData
		let api = '/recruitment/getAllData';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		if (startDate) {
			url = url + "?start_date=" + startDate
		}
		if (endDate) {
			url = url + "&end_date=" + endDate
		}
		return this.http.get(url);
	}

	// BulkpushTemplate API
	bulkTemplateAPI(api_end: any): Observable<any> {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/smbBulkTemplate
		let api = "/smbBulkTemplate";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url, { observe: 'response' as 'response', responseType: 'blob' })
	}

	getClientUploadTemplate(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/recruitment/client_update_template
		let api = "/recruitment/client_update_template";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url, { observe: 'response' as 'response', responseType: 'blob' })
	}
	bulkDataTransferAPI(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = "/common/event/datatransfer";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	// Bulk Upload API
	bulkUploadAPI(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/bulkuploadnew';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	// Client Upload API
	clientStatusUploadAPI(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/recruitment/postClientStatus
		let api = '/recruitment/postClientStatus';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	// Bulk Push API
	bulkPushAPI(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkpush
		let api = '/bulkpush';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	getCamapignsAPI(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/campaignDataDetails
		let api = '/campaignDataDetails';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getCampaignAgentsData(obj: any, api_end?: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/agentCampaignData
		let api = '/agentCampaignData';
		// let url = this.base_url + this.base_url_version + api;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		// console.log('agent campaign data', url)
		return this.http.post(url, obj);
	}

	addAgentstoCampaign(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in//api/v1/campaign/addagents
		let api = '/campaign/addagents';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	updateAgentStatus(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in//api/v1/campaigns/switch
		let api = '/campaigns/switch';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	downloadCampaignStatusReport(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/uearn_campaign_status_report
		let api = '/uearn_campaign_status_report';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	downloadSBICampaignStatusReport(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/uearn_campaign_status_report
		let api = '/campaign_status_report';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	commonFeedbackAPI(start_date: string | moment.Moment, end_date: string | moment.Moment, api_end: any, hide_customer_number?: boolean | any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/common/feedbackData?start_date=2020-11-01T18:30:00&end_date=2020-11-12T18:30:00
		let api = '/common/feedbackData?start_date=' + start_date + '&end_date=' + end_date;
		if (hide_customer_number) {
			api += '&hcd=true'
		}
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	//downloadReportFromUrl(url: string, no_host?: boolean, fileName?: string) {
	downloadReportFromUrl(url: string, no_host: boolean, api_end: any, fileName?: string) {
		this.base_url = this.getprotocal_from_url();
		// Download the report from url;
		let loc_url = url;
		if (no_host) {
			loc_url = (api_end ? api_end : this.base_url) + url;
		}
		//console.log('final url', loc_url);
		let promise = new Promise((resolve, reject) => {
			this.http.get(loc_url, { observe: 'response' as 'response', responseType: 'blob' }).subscribe(
				(res: any) => {
					// console.log('api response', res)
					if (res.errors) {
						reject(res.errors)
					} else {
						try {
							let content_type = 'application/octet-stream';
							let file_name = loc_url.split('/').pop();
							if (fileName) {
								file_name = fileName;
							}
							if (res.headers.get('content-type')) {
								content_type = res.headers.get("content-type");
							}
							if (content_type == 'audio/mpeg') {
								file_name += '.mp3'
							}
							// console.log('content type', content_type, file_name)
							let body = res.body;
							let blob: any = new Blob([body], { type: content_type });
							let url = window.URL.createObjectURL(blob);
							var anchor = document.createElement("a");
							anchor.download = file_name;
							anchor.href = url;
							anchor.click();
							resolve(null);
						} catch (e) {
							reject();
						}
					}
				}
			)
		})
		return promise;
	}

	downloadCmailsReport(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/cmail_mails?incsales=1&userset=Team';
		api += '&start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		api += "&download=true";
		api += "&offset=" + this.offset;
		api += "&campaign=" + obj.campaign_name;
		if (obj.unique_data) {
			api += "&unique_data=true";
		}
		if (obj.hcd) {
			api += "&hcd=true";
		}
		console.log("---1--")
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	downloadCustomerStatusReport(api_end: any, start_date: string, end_date: string, campaign_name: string, hcd?: boolean) {
		debugger;
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		//let hcd1 = ((hcd==true)?true:false);
		let api = '/cmail_mails?incsales=1&userset=Team';
		api += '&start_date=' + start_date;
		api += '&end_date=' + end_date;
		api += "&download=true";
		api += "&offset=330";
		api += "&unique_data=true";
		if (hcd) {
			api += '&hcd=' + hcd;
		}
		api += "&campaign=" + campaign_name;
		console.log("---2--")
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	downloadCustomerStatusReportwithoutcallrecording(api_end: any, start_date: string, end_date: string, campaign_name: string, hcd: boolean) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		//let hcd1 = ((hcd==true)?true:false);
		let api = '/cmail_mails?incsales=1&userset=Team';
		api += '&start_date=' + start_date;
		api += '&end_date=' + end_date;
		api += "&download=true";
		api += "&offset=330";
		api += "&unique_data=true";
		if (hcd) {
			api += '&hcd=' + hcd;
		}
		api += "&without_callrecord=true";
		api += "&campaign=" + campaign_name;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		console.log("---3--")
		return this.http.get(url);
	}

	downloadCallStatusReport(start_date: string, end_date: string, campaign_name: string, api_end: any, hcd?: boolean) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/cmail_mails?incsales=1&userset=Team';
		api += '&start_date=' + start_date;
		api += '&end_date=' + end_date;
		api += "&download=true";
		api += "&offset=330";
		api += "&campaign=" + campaign_name;
		if (hcd) {
			api += "&hcd=true"
		}
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		// console.log("---4--")
		return this.http.get(url);
	}
	downloadCallStatusReportPost(post_obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/cmail_mails';

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		console.log("---4--")
		return this.http.post(url, post_obj);
	}
	downloadCallStatusReportwithoutmask(start_date: string, end_date: string, campaign_name: string, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/cmail_mails?incsales=1&userset=Team';
		api += '&start_date=' + start_date;
		api += '&end_date=' + end_date;
		api += "&download=true";
		api += "&offset=330";
		api += "&campaign=" + campaign_name;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		console.log("---4--")
		return this.http.get(url);
	}

	downloadAuditOutboundReport(startDate, endDate, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/audit_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	downloadAgentLoginInboundReport(startDate, endDate) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/common/agent_login_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = this.base_url + this.base_url_version + api;
		return this.http.get(url);
	}
	downloadAgentActivityInboundReport(startDate, endDate, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/common/agent_activity_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	downloadCallStatusInboundReport(startDate, endDate, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/common/inbound_call_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	downloadCallStatusOutboundReport(obj, type: string, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/cmail_mails';
		if (type && type == 'Archived') {
			api = '/archived/cmail_mails';
		}
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	downloadSharekhanCallReport(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST -- https://youearn.in/api/v1/sharekhan/call_report
		let api = "/sharekhan/call_report";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	// Campaign Switch API
	campaignSwitch(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST -- https://youearn.in/api/v1/campaigns/switch
		let api = '/campaigns/switch';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	// Get Campaign Logs
	getCampaignLogs(campaign_id: number, api_end: string) {
		this.base_url = this.getprotocal_from_url();
		let api = '/campaign/activities?campaign_id=' + campaign_id;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}

	/*********  Recruiter and Campaign Dashboard APIs ends *************/

	/********* Tech Support API starts  ******/
	getIssues(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/tech/tickets?start_date=2020-07-14T18:30:00&end_date=2020-07-15T18:29:59
		let api = '/tech/tickets';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = this.base_url + this.base_url_version + api;
		return this.http.get(url);
	}
	updateTicket(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// PUT API - https://youearn.in/api/v1/tech/ticket
		let api = '/tech/ticket';
		let url = this.base_url + this.base_url_version + api;
		return this.http.put(url, obj);
	}
	/********* Tech Support API ends  ******/

	/* Project Manager Dashboard APIs starts */
	pmDashboard(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/common/dashboard';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGenericChannelsData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//POST API - https://youearn.in/api/v1/common/channelSummary
		let api = '/common/channelSummary';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGenericTimersData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/common/timer_counts
		let api = '/common/timer_counts';
		/* api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		if (obj.isHistory) {
			api += '&isHistory=true';
		} */
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGenericCustomerData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/getGenericCustomerData';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getCentralGenericCustomerData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/getGenericCustomerDataforcentral';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGenericCampaigns(obj: any, api_end: any) {
		//debugger;
		this.base_url = this.getprotocal_from_url();
		let api = '/getGenericCampaignData';

		let url
		if (obj.start_data) {
			api += '?start_date=' + obj.start_date;
			api += '&end_date=' + obj.end_date;
			url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		}
		if (obj.campaign_id) {
			api += '?campaign_id=' + obj.campaign_id;
			url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
			//url = this.base_url + this.base_url_version + api;

			//console.log(url);
		}
		return this.http.post(url, obj);
	}
	genericCampaignDataDetails(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/genericCampaignDataDetails';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	genericCallDataDetails(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//POST API - https://youearn.in/api/v1/getGenericCallsData
		let api = '/getGenericCallsData';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	genericCallsDataForUsers(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//POST API - https://youearn.in/api/v1/general/callsDataUsers
		let api = '/general/callsDataUsers';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getAllGroupStatus(groupId: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/getAllGroupStatus?jsonresponse=1&group=' + groupId;
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.get(url);
	}

	recruiterDashboard(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/recruitment/hiring_agents_dashboard';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	pmDashboardInbound(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/common/inbounddashboard
		let api = '/common/inbounddashboard';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	pmDatewiseSlots(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/getDatewiseSlots';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getAgentActivityAPI(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/common/agent_login_report
		let api = '/common/agent_login_report';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		api += '&json_response=true';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getGroupAgentActivityAPI(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/common/agent_activity_times_group
		let api = "/common/agent_activity_times_group";
		api += "?start_date=" + obj.start_date;
		api += "&end_date=" + obj.end_date;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getActivityTimeperAgent(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/common/agent_activity_times
		let api = '/common/agent_activity_times';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		api += '&for_user=' + obj.for_user;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	/* Project Manager Dashboard APIs ends */

	/* OPS Onboarding Dashboard */
	getOPSDATA(startDate, endDate, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/recruitment/ops_data
		let api = '/recruitment/ops_data';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		if (startDate && endDate) {
			url = url + `?start_date=${startDate}&end_date=${endDate}`
		}
		return this.http.get(url);
	}
	getOPSTSDATA(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/recruitment/ops_data_ts
		let api = '/recruitment/ops_data_ts';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	documentUpload(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/recruitment/web_doc_upload
		let api = '/recruitment/web_doc_upload';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	updateApplicantData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/recruitment/updatedRecData
		let api = '/recruitment/updatedRecData';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.put(url, obj)
	}
	/* OPS Onboarding Dashboard ends */
	/* Training Module APIs starts */
	getClientsName(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/clients';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	selectAClient(company) {
		this._selectClient = "";
		this._selectClient = company;
	}
	getTrainingDashboard(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/dashboard';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		// url=url+'?for_company='+obj.name;
		url = this.bindTrainingGetParams(url)
		return this.http.get(url);
	}

	getAgentList(status: string = "", batchCode: number = 0, forCreateBatch: string = "", urlExtend: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/agents';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		// url=url+'?for_company='+obj.name;
		url = this.bindTrainingGetParams(url)
		if (status) {
			url = url + '&agent_status=' + status;
		}
		if (batchCode) {
			url = url + '&batch_code=' + batchCode;
		}
		if (forCreateBatch) {
			url = url + '&forCreateBatch=' + forCreateBatch;
		}
		if (urlExtend) {
			url = url + urlExtend;
		}
		return this.http.get(url);
	}

	getTrainer(api_end: any) {
		let api = '/training/trainers';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		if (this._selectClient) {
			url = this.bindTrainingGetParams(url)
		}
		return this.http.get(url);
	}
	// getBatchList(){
	// 	let obj={groupid:2053,startdate:'2020-08-19',endDate:'2020-09-30'}
	// 	let api = '/training/batches';
	// 	let url = this.base_url + this.base_url_version + api;
	// 	url=url+'?for_group='+obj.groupid+'&start_date='+obj.startdate+'&end_date='+obj.endDate;
	// 	return this.http.get(url);
	// }
	getBatchList(startDate: string = "", endDate: string = "", trainingType: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/batches';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = this.bindTrainingGetParams(url) //default parameters
		if (startDate) {
			url = url + `&start_date=${startDate}`
		}
		if (endDate) {
			url = url + `&end_date=${endDate}`
		}
		if (trainingType) {
			url = url + `&training_type=${trainingType}`
		}
		return this.http.get(url);
	}
	saveBatch(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/batch';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		obj = this.bindTrainingPostParams(obj)
		return this.http.post(url, obj);
	}


	editBatchBind(element: any) {
		this._editBatchObj = { "element": element }
		return this._editBatchObj;
	}
	editBatch() {
		return this._editBatchObj;
	}
	modifyBatch(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/batch';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.put(url, obj);
	}
	clearCreateforBtn() {
		this._editBatchObj = "";
		return this._editBatchObj;
	}
	deleteBatch(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/deletebatch';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	trainersAllTrainersList(startDate: string = "", endDate: string = "", trainerSts: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/trainers';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = this.bindTrainingGetParams(url)
		if (startDate) {
			url = url + `&start_date=${startDate}`
		}
		if (endDate) {
			url = url + `&end_date=${endDate}`
		}
		if (trainerSts) {
			url = url + `&trainer_status=${trainerSts}`
		}
		return this.http.get(url);
	}
	sessionTraingbatchID(batchid: any) {
		this.base_url = this.getprotocal_from_url();
		this.meetingLoacationLink = batchid.training_location
		this.batchidTraining = batchid.id;
		return this.batchidTraining;

	}
	getmeetingLinkForBatch() {
		return this.meetingLoacationLink;
	}
	getBatchIDSession() {
		return this.batchidTraining;
	}
	getBatchTrainingSession(id: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let obj = id;
		let api = '/training/batchsessions';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + '?batch_code=' + obj;
		return this.http.get(url);
	}
	getAttendence(_objAttendence: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let obj = _objAttendence[0];
		let api = '/training/attendance';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + '?batch_code=' + obj.batch_code + '&session_id=' + obj.batch_session_id;
		return this.http.get(url);
	}
	updateAttendence(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/attendance';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	/* Training Module APIs ends */
	postCmailUser(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/cmail_users.json?ver=2';
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	// Logout API
	logoutAPI(api_end: any, obj: any = {}, token: any = '') {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/web/logout;
		let api = "/web/logout";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		if (obj && obj.id & token) {
			url = url + '?user_id=' + obj.id + '&group_id=' + obj.groupid + `&access_token=${token}`;
		}
		return this.http.get(url)
	}
	logoutAPIUearn(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// GET API - https://youearn.in/api/v1/logout;
		let api = "/web/logoutuearn";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}
	forceLogout(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/forceLogout;
		let api = "/forceLogout";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	bindTrainingGetParams(url) {
		this.base_url = this.getprotocal_from_url();
		if (this._selectClient) {
			url = url + url.includes('?') ? url : url + '?'
			url = url + `?for_company=${this._selectClient.name}&for_group=${this._selectClient.groupid}&recruitment_group=${this._selectClient.recruitment_groupid}&trainer_group=${this._selectClient.trainer_groupid}`;
			return url;
		}
	}
	bindTrainingPostParams(obj) {
		obj.for_company = this._selectClient.name;
		obj.for_group = this._selectClient.groupid;
		obj.recruitment_group = this._selectClient.recruitment_groupid;
		obj.trainer_group = this._selectClient.trainer_groupid;
		return obj
	}
	getManagmentTeam(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/team';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	bulkSlotRegister(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/bulkSlotRegister';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	updateCustomStages(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/settings/updateCustomStages';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	editSlot(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/edit_slot';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getCalanderData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/calender';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = this.bindTrainingGetParams(url);
		url = url + '&start_date=' + obj.start_date + '&end_date=' + obj.end_date;
		return this.http.get(url);
	}

	saveTeamManagement(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/setgroupsofuser';
		let url = (api_end ? api_end : this.base_url) + "/api" + api;
		return this.http.post(url, obj);

	}
	updateTeamManagement(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/updategroupuser';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);

	}
	deleteTeamManagement(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/deletegroupsofuser';
		let url = (api_end ? api_end : this.base_url) + "/api" + api;
		url = url + '?user_id=' + obj.user_id + '&dependent_id=' + obj.dependent_id
		return this.http.get(url);

	}
	validateEmailUserTeamManagement(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/validateuser';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + '?for_email=' + obj._email + '&for_phone=' + obj._mobileNo;
		return this.http.get(url);
	}
	getReportList(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let _forGroup = obj.for_group;
		let api = '/training/reports';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = this.bindTrainingGetParams(url);
		if (obj.start_date && obj.end_date) {
			url = url + `&start_date=${obj.start_date}&end_date=${obj.end_date}`;
		}
		//console.log(url)
		return this.http.get(url);
	}
	getCertificationBatch(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let _forGroup = obj.for_group;
		let _forcertificate = obj.for_certificate;
		let api = '/training/batches';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = this.bindTrainingGetParams(url);
		url = url + '&for_certificate=' + _forcertificate;
		return this.http.get(url);
	}
	getCertificationList(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let _for_company = this._selectClient.name;
		let _forGroup = obj.for_group;
		let _forcertificate = obj.for_certificate;
		let _batch = obj.batch_code;
		let _batch_type = obj.batch_type;
		let api = '/training/agents';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = this.bindTrainingGetParams(url);
		url = url + '&for_certificate=' + _forcertificate;
		if (_batch) {
			url = url + '&batch_code=' + obj.batch_code;
		}
		if (_batch_type) {
			url = url + '&batch_type=' + _batch_type;
		}
		return this.http.get(url);
	}
	postCerification(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/certify';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	sendNotifications(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/sendnotification';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGeneralVoiceAuditSummary(client_id: number, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/general/auditCallSummary?client_id=${client_id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}
	getVoiceCallAudit(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let pId = 71217;
		let api = '/auditParameters';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + '?audit_project=' + pId;
		return this.http.get(url);
	}
	getFetchCallRecord(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let pId = 71217;
		let api = '/fetch_call_record';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + '?audit_project=' + pId;
		return this.http.get(url);
	}
	saveCallAudit(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/submitAudit';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGeneralCallLoadAudit(client_id: number, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/fetch_call_record?audit_project=${client_id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	/* FK_EMP Project API starts */
	getfk_emp_dashboard(obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/fk_emp/get_calls?';
		api += 'start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = this.base_url + this.base_url_version + api;
		return this.http.get(url);
	}
	/* FK_EMP project API ends */
	getClientsAudit(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/clients';
		let url = this.base_url + this.base_url_version + api + '?production_group=' + id;
		return this.http.get(url);
	}
	getClientsAuditGroup(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/clientGroups';
		let url = this.base_url + this.base_url_version + api + '?production_group=' + id;
		return this.http.get(url);
	}
	saveNewAuditClient(obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/createClient';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	saveNewAuditForm(obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/createForm';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getClientsAuditors(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/auditors';
		let url = this.base_url + this.base_url_version + api + '?client_id=' + id;
		return this.http.get(url);
	}
	getClientsAuditorsByGroup(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/auditorsGroup';
		let url = this.base_url + this.base_url_version + api + '?production_group=' + id;
		return this.http.get(url);
	}
	getAuditDashboard(params: string) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/dashboard';
		let url = this.base_url + this.base_url_version + api + params;
		return this.http.get(url);
	}
	getProjectAuditors(id: number, clientId: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/projectAuditors';
		let url = this.base_url + this.base_url_version + api + '?production_group=' + id + '&client_id=' + clientId;
		return this.http.get(url);
	}
	assignAuditorsToProjects(obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/assignAuditors';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getCallForAudit(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/call';
		let url = this.base_url + this.base_url_version + api + '?client_id=' + id;
		return this.http.get(url);
	}
	updateCallAudit(obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/updateCall';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	saveCallAuditExtermal(obj: any,) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/saveCallAuditExtermal';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getCallAuditSummary(id: number, startDate, endDate) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/callSummary';
		let url = this.base_url + this.base_url_version + api + '?client_id=' + id + `&start_date=${startDate}&end_date=${endDate}`;
		return this.http.get(url);
	}
	getLastCallFill(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/last_fill';
		let url = this.base_url + this.base_url_version + api + '?client_id=' + id;
		return this.http.get(url);
	}
	downloadAuditProjectReport(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/uearn_campaign_status_report
		let api = '/audit/projectReport';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	downloadAuditAuditorReport(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/uearn_campaign_status_report
		let api = '/audit/auditorReport';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	downloadAuditCombineReport(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/uearn_campaign_status_report
		let api = '/audit/auditorReport';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	downloadAuditEarningsReport(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/uearn_campaign_status_report
		let api = '/audit/auditorEarning';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getAuditReportGeneratedList(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/generatedReport';
		let url = this.base_url + this.base_url_version + api + '?production_group=' + id;
		return this.http.get(url);
	}
	getAllCallsAudit(id: number, obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/allCalls';
		let url = this.base_url + this.base_url_version + api + '?production_group=' + this._selectClient.production_group;
		if (obj.start_date && obj.end_date) {
			url = url + `&start_date=${obj.start_date}&end_date=${obj.end_date}`;
		}
		return this.http.get(url);
	}
	getAuditorAssignedProject(id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/getAssignedProject';
		let url = this.base_url + this.base_url_version + api + '?auditor_id=' + id;
		return this.http.get(url);
	}
	getCallForms(id: number, api_end?: string) {
		this.base_url = this.getprotocal_from_url();
		let api = '/campaign/callForms';
		// let url = this.base_url + this.base_url_version + api + '?production_group=' + id;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api + '?production_group=' + id;
		console.log('callforms api', url)
		return this.http.get(url);
	}
	saveCallForm(obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/campaign/addCallForm';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	followupCount(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/common/followupCount';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	passwordReset(obj: any) {
		// this.base_url = this.getprotocal_from_url();
		let api = '/reset_password_mail';
		let url = this.base_url + api;
		return this.http.post(url, obj);
	}
	changePassword(obj: any, token: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/changepwd_withtoken';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;

		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'x-access-token': token
		});
		let options = { headers: headers };


		return this.http.post(url, obj, options);
	}
	saveJoin(obj: any) {
		//this.base_url = this.getprotocal_from_url();
		let api = '/cmail_users.json?ver=2';
		let url = this.base_url + api;
		console.log(url);
		return this.http.post(url, obj);
	}
	saveJoinBusiness(obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/demo_request';
		let url = this.base_url_comnex + api;
		return this.http.post(url, obj);
	}
	saveFaq(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/supportticket';
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	placeCallPhoneSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/placecall';
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	getChannelsCampaign(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/linktrackdata';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getAssessmentForCandidate(all: boolean = false, user_id: string = "", production_group: string = "", type: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/assessment/questionaire';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		if (production_group) {
			if (this._selectClient) {
				url = url + `?production_group=${this._selectClient.groupid}`
			} else {
				url = url + `?production_group=${production_group}`
			}

		}
		if (user_id) {
			url = url + `&agent_user_id=${user_id}`
		}
		if (all) {
			url = url + `&all=true`
		}
		if (type) {
			url = url + `&assessment_type=${type}`
		}



		return this.http.get(url);
	}
	validateQuestionaire(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/assessment/validateQuestionaire';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	uploadCVImage(obj: any, userId: string, folder: string, count: number = 0, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/assessment/uploadImage';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api + `?userId=${userId}&folder=${folder}&count=${count}`;
		return this.http.post(url, obj);
	}
	uploadCVImageDirect(obj: any, userId: string, folder: string, count: number = 0) {
		this.base_url = this.getprotocal_from_url();
		let api = 'http://3.6.29.250:5056/facec/';
		let url = api + `${userId}`;
		return this.http.post(url, obj);
	}
	getAssessmentTemplate(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/assessment/get_assessment_excel';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url, { observe: 'response' as 'response', responseType: 'blob' })
	}
	getTrainingAddCandidatesTemplate(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/training/get_training_add_excel';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url, { observe: 'response' as 'response', responseType: 'blob' })
	}
	getAuditFormTemplate(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/get_form_excel';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url, { observe: 'response' as 'response', responseType: 'blob' })
	}
	getAssessmentTemplateUrl(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/assessment/get_assessment_excel';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return url;
	}
	assessmentUploadAPI(obj: FormData, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/assessment/upload';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		if (this._selectClient) {
			url = url + `&production_group=${this._selectClient.groupid}`
			obj.append('production_group', this._selectClient.groupid);
		} else {
			url = url + `&production_group=${groupId}`
			obj.append('production_group', groupId);

		}
		return this.http.post(url, obj);
	}
	assessmentUpdate(obj: FormData, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/assessment/update';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		if (this._selectClient) {
			url = url + `&production_group=${this._selectClient.groupid}`
			obj.append('production_group', this._selectClient.groupid);
		} else {
			url = url + `&production_group=${groupId}`
			obj.append('production_group', groupId);
		}
		return this.http.post(url, obj);
	}
	assessmentDelete(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/assessment/delete';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	recruitmentSingle(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/recruitment/insert';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	updateProfileStatus(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/cmail_users/updatestatus';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	updateRoleStatus(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/cmail_users/updateRole';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	updateVisionStatus(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/cmail_users/updateVision';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	updateFeedbackForm(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/cmail_users/updatefeedbackform';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	//start agent plugin
	agentPluginHome(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/uearn/getdashboard';
		api += '?user_id=' + obj.userid;
		api += '&start_date=' + obj.start_time;
		api += '&end_date=' + obj.end_time;
		api += '&agents_list=' + obj.agents_list;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	agentPluginContacted(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/common/event/contacted';
		api += '?start_date=' + obj.start_time;
		api += '&end_date=' + obj.end_time;
		api += '&user_id=' + obj.userid;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	agentPluginGetAgentSlot(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/getSlotList';
		api += '?user_id=' + obj.userid;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	agentPluginGetUearnedMonthPassbook(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/uearn/passbook/month_wise';
		api += '?today=' + obj.startTime;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	agentPluginGetUearnPassbookLifetime(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/uearn/passbook/lifetime';
		api += '?user_id=' + obj.userid;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	agentPluginGetUearnDayWisePassbook(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/uearn/passbook/day_wise';
		api += '?user_id=' + obj.userid;
		api += '&start_date=' + obj.start_time;
		api += '&end_date=' + obj.end_time;
		api += '&agents_list=' + obj.userid;
		api += '&company=' + obj.company;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}

	agentPluginGetRequestForBookingSlot(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/userregisterSlot';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}

	agentRequestForBookingSlot(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//'/api/v1/userregisterSlot'
		let api = '/userregisterSlot';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	getagentBookedSlot(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/getUsersforSlot';
		api += '?slot_id=' + obj;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	getAuditScores(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/teamauditscore';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	agentPluginGetRequestForCancelSlot(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/usercancelSlot';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	agentPluginDashboardList(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/common/event/list';
		api += '?user_id=' + obj.userid;
		api += '&start_date=' + obj.start_time;
		api += '&end_date=' + obj.end_time;
		api += '&skip=' + obj.skip;
		api += '&calendar_type=' + obj.calendar_type;
		api += '&top=' + obj.top;
		api += '&get_desktop_appointments=1';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	message(obj: any) {
		this.base_url = this.getprotocal_from_url();
		const api = "/message"
		var url = this.base_url_chat + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	leadsConnected(count: any) {
		this.base_url = this.getprotocal_from_url();
		this._countLeadsConnected = count;
		return this._countLeadsConnected;
	}
	followupTodo(count: any) {
		this.base_url = this.getprotocal_from_url();
		this._countfollowupTodo = count;
		return this._countfollowupTodo;
	}
	subscribe(obj: any) {
		this.base_url = this.getprotocal_from_url();
		const api = "/subscribe"
		var url = this.base_url_chat + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	subscribeChatv2(obj: any) {
		const api = "/subscribechat"
		var url = this.base_url_chat + this.base_url_version_mobile + api;
		return this.http.post(url, obj, { responseType: 'text' });
	}
	//getWidgetUserByPhone(phone: any) {
	getWidgetUserByPhone(phone: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/campaignDataDetails
		let api = '/widget/userByPhone?phone=' + phone;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	salesStatus(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		const api = "/salesstatus";
		var url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	callDisconnect(obj: any, server_url: string = "") {
		const api = "/calldisconnect"
		var url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	customActionTrigger(obj: any, server_url: string = "", action_url: string = "") {
		this.base_url = this.getprotocal_from_url();
		const api = action_url
		var url = (server_url ? server_url : this.base_url) + api;
		return this.http.post(url, obj);
	}
	siptogsmcall(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = "/siptogsmcall"
		//var url = (server_url ? server_url : this.base_url) + this.base_url_version + api;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	gsmtogsmcall(obj: any, server_url: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		const api = "/siptogsmcall"
		var url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	startReceivingCalls(userId: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/campaignDataDetails
		let api = `bcp/remotedialstart?user_id=${userId}&options=Ready%20to%20take%20any%20call`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url);
	}
	getSipUserByPhone(sipId: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		const api = '/sip/userById?sip_id=' + sipId;
		var url = (api_end ? api_end : this.base_url) + api;
		return this.http.get(url);
	}
	getSipUserByPhonePromo(sipId: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		const api = '/sip/userById?sip_id_promo=' + sipId;
		var url = (api_end ? api_end : this.base_url) + api;
		return this.http.get(url);
	}
	agentStartEndSession(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//this.base_url= this._service.get_API_End_Point();
		let api = "/app_status"
		//var url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	remoteDialStart(userId: any, server_url: string = "") {
		this.base_url = this.getprotocal_from_url();
		const api = "/bcp/remotedialstart"
		var url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api + `?user_id=${userId}&options=Ready%20to%20take%20any%20call`;
		return this.http.get(url);
	}

	remoteDialStop(userId: any, api_end: any, obj: any) {
		//remoteDialStop(userId: any, server_url: string = "") {
		this.base_url = this.getprotocal_from_url();
		const api = "/bcp/remotedialstop"
		var url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api + `?user_id=${userId}&options=${obj}`;
		return this.http.get(url);
	}
	//end agent plugin
	getCallTransscription(call_id: number, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/transcription';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api + `?call_id=${call_id}`;
		return this.http.get(url);
	}
	transformCall(urlCall: string, call_id: number) {
		this.base_url = this.getprotocal_from_url();
		let api = `http://35.207.216.141:6000/transcribe_audio_url?url=${urlCall}&call_ref=${call_id}`;
		// let url = this.base_url + this.base_url_version + api + `?call_id=${call_id}`;
		return this.http.get(api);
	}
	auditFillData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit_fill_data';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	remotePushToken(userId: any, token: any, server_url: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		const api = "/common/registerdevicepushnotifications"
		var url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api + `?user_id=${userId}&devicekey=${token}`;
		return this.http.get(url);
	}

	assessmentUpdateFradulent(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/assessment/fradulent';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	uploadAudioRecord(obj: any, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/upload/callRecordExternal';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api + `?group_id=${groupId}`;
		return this.http.post(url, obj);
	}
	getListCallRecords(groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/upload/listCallRecordExternal?group_id=${groupId}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getCallRecordExtUrl(file, groupid, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		return `${(api_end ? api_end : this.base_url)}/call_records/${groupid}/${file}`
	}
	processTranscriptionExternal(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		const api = "/audit/processTranscriptionExternal"
		var url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	checkAssessmentGiven(groupId, userId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//this.base_url= this._service.get_API_End_Point();
		let api = `/assessment/checkSubmitted?production_group=${groupId}&user_id=${userId}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	createTeamAfterRecruitment(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/admin/create_team_onboard';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	sendJSONBYEmail(obj: SEND_EMAIL_BYJSON, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/common/sendJSONasEmail
		let api = "/common/sendJSONasEmail";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	bulkUploadVoice(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/common/sendJSONasEmail
		let api = "/biometric/voice/bulkUpload";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	validateVoice(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/common/sendJSONasEmail
		let api = "/biometric/voice/validate";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	uplaodFileServer(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/biometric/voice/uploadFileToUrl';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	sendAppLink(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/send_app_link';
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	getClientConfig(reference) {
		//this.base_url = this.getprotocal_from_url();
		let api = `/config/clientDetails?reference=${reference}`;
		let url = this.base_url + api; //(api_end ? api_end : )
		return this.http.get(url);
	}
	getClientConfigByRecruitment(recruitment_groupid, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/config/clientDetails?recruitment_groupid=${recruitment_groupid}`;
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.get(url);
	}
	getBerge(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/addParticipant';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getBergeToWishper(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/bargeToWhisper';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getWishperToBerge(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/whisperToBarge';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getAllFormsByAuditClientId(audit_client_id, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/audit/allForms?audit_client_id=${audit_client_id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getAllCallsByAuditClientIdForAudit(production_group, startDate, endDate, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/audit/allCalls?production_group=${production_group}&audit_status=0&start_date=${startDate}&end_date=${endDate}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	c2cCallApiSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = (api_end ? api_end : this.base_url) + '/callCustomer';
		let url = api;
		return this.http.post(url, obj);
	}
	smsCallApiSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = (api_end ? api_end : this.base_url) + '/chat/message';
		let url = api;
		return this.http.post(url, obj);
	}
	whatsappCallApiSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = (api_end ? api_end : this.base_url) + '/chat/message';
		let url = api;
		return this.http.post(url, obj);
	}
	obdCallApiSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = (api_end ? api_end : this.base_url) + '/createdobd';
		let url = api;
		return this.http.post(url, obj);
	}
	dobdCallApiSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = (api_end ? api_end : this.base_url) + '/createdobd';
		let url = api;
		return this.http.post(url, obj);
	}
	sipCallApiSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = (api_end ? api_end : this.base_url) + '/siptogsmcall';
		let url = api;
		return this.http.post(url, obj);
	}
	emailCallApiSave(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = (api_end ? api_end : this.base_url) + '/sendmailstocustomers';
		let url = api;
		return this.http.post(url, obj);
	}
	getAuditForm(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/audit/allForms
		let api = `/audit/allForms?audit_client_id=${this._selectClient.id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	insertAuditForm(obj: any, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/createAuditForm';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		if (this._selectClient) {
			url = url + `&production_group=${this._selectClient.groupid}`
			obj.append('production_group', this._selectClient.groupid);
		} else {
			url = url + `&production_group=${groupId}`
			obj.append('production_group', groupId);

		}
		return this.http.post(url, obj);
	}

	//update Audit form details
	updateAuditForm(obj: any, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/updateForm';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		if (this._selectClient) {
			url = url + `&production_group=${this._selectClient.groupid}`
			obj.append('production_group', this._selectClient.groupid);
		} else {
			url = url + `&production_group=${groupId}`
			obj.append('production_group', groupId);

		}
		return this.http.post(url, obj);
	}
	//xlsx
	audittUpdate(obj: FormData, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/audit/upload';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		if (this._selectClient) {
			url = url + `&production_group=${this._selectClient.groupid}`
			obj.append('production_group', this._selectClient.groupid);
		} else {
			url = url + `&production_group=${groupId}`
			obj.append('production_group', groupId);
		}
		return this.http.post(url, obj);
	}

	//Delete Audit form details
	deleteAuditForm(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/audit/deleteForm?id=${obj.id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getAssessmentHistory(agent_user_id, assessment_type, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/assessment/history?agent_user_id=${agent_user_id}&assessment_type=${assessment_type}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	markForMockCalling(agent_user_id, agent_group_id, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/assessment/markForMockCalling?agent_user_id=${agent_user_id}&agent_group_id=${agent_group_id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	uploadAnyFile(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/events.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	uploadChatFile(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();


		//this.base_url = 'https://desktopio.contiinex.com';
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/chat_events.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		//let url = this.base_url + this.base_url_version + api;

		return this.http.post(url, obj);
	}
	sendTranscriptionEmail(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/sendtransmission_email';
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	trainingNewCandidateUpload(obj: FormData, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/training/uploadNewCandidates';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		//console.log(this._selectClient)
		if (this._selectClient) {
			url = url + `?production_group=${this._selectClient.groupid}&company_name=${this._selectClient.name}`
			obj.append('production_group', this._selectClient.groupid);
			obj.append('company_name', this._selectClient.name);
		} else {
			url = url + `?production_group=${groupId}`
			obj.append('production_group', groupId);

		}
		return this.http.post(url, obj);
	}
	auditAutomation(obj: any, endpoint: string) {
		this.base_url = this.getprotocal_from_url();
		const api = "/audit"
		var url = (this.base_url_ainex) + endpoint;
		return this.http.post(url, obj);
	}
	recruitmentNewCandidateUpload(obj: FormData, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/recruitment/uploadNewCandidates';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		//console.log(this._selectClient)

		return this.http.post(url, obj);
	}
	// /audit/auditedcallSummary
	getAuditedCallSummary(id: number, startDate, endDate, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/audit/totalauditedcall';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api + '?client_id=' + id + `&start_date=${startDate}&end_date=${endDate}`;
		return this.http.get(url);
	}
	getAgentInfoAudit(agent_id: number, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/audit/agentInfo?agent_id=${agent_id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}
	fetchLinkedinProfile(urlExtend: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/linkedin/userInfo${urlExtend}`;
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.get(url)
	}

	addmoredata(obj: any, query, groupId, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/leads/addData';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		url = url + query;
		if (this._selectClient) {
			url = url + `&production_group=${this._selectClient.groupid}`
			obj.append('production_group', this._selectClient.groupid);
		} else {
			url = url + `&production_group=${groupId}`
			obj.append('production_group', groupId);

		}
		return this.http.post(url, obj);
	}
	getWaDashboard(customer, project, time_range, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = "/whatsapp/dashboard?view_type=" + time_range;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}
	// getChannelReportWA(group_id,start_data: any = "", end_date: any = "") {
	// 	var url = this.base_url + this.base_url_version + "whatsapp/report"+"?group_id=" + group_id;
	// 	if(start_data && end_date){
	// 	  url = url + `&start_date=${start_data}&end_date=${end_date}`;
	// 	}
	// 	console.log('getChannelReportWA',url);
	// 	return this.http.get(url);
	//   }
	getChannelReportWA(group_id, start_data: any = "", end_date: any = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/whatsapp/report" + "?group_id=" + group_id;
		if (start_data && end_date) {
			url = url + `&start_date=${start_data}&end_date=${end_date}`;
		}
		//console.log('getChannelReportWA', url);
		return this.http.get(url);
	}

	getPaymentdashboardDetails(obj: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = "/payment/get_full_details";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}

	savePaymentConfigure(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/payment/create_config';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getPaymentHistory(obj: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = "/payment/get_config";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}
	paymentRequestToUser(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/payment/payment_request_toUser';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	paymentAddReaxationAmount(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/payment/add_reaxation_amount';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}


	sipOutboundCall(obj: any, server_url: string = "", endPoint: any = "") {
		this.base_url = this.getprotocal_from_url();
		const api = endPoint
		var url = api;
		console.log(url, "\n", obj);
		return this.http.post(url, obj);
	}

	getContactsWhtasapp(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/common/event/contacts?user_id=${obj.user_id}`;
		var url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api; //(server_url ? server_url : )
		return this.http.get(url, obj);
	}
	saveContactsWhatsapp(obj: any, server_url: string = "") {
		this.base_url = this.getprotocal_from_url();
		let api = `/chat/send_message`;
		var url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}


	getPaymentdashboardTable(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/payment/agent_earnings_date?start_date=${obj.start_date}&end_date=${obj.end_date}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url)
	}
	uploadPaymentExcel(formData: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/payment/upload_payment_excel`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, formData);
	}
	uploadPaymentUpdate(formData: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/payment/final_payment_update`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, formData);
	}
	getPaymentFiles(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/payment/history_push?start_date=${obj.start_date}&end_date=${obj.end_date}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	saveKnowMoreEnquiry(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let url = (api_end ? api_end : this.base_url) + "/know_more_enquiry";
		return this.http.post(url, obj);
	}
	loginAinexAPI(obj: login_data) {
		this.base_url = this.getprotocal_from_url();
		var url = this.base_url_ainex + "/api/v2/gettoken";
		return this.http.post(url, obj);
	}

	loginUearnAPI(obj: login_data) {
		this.base_url = this.getprotocal_from_url();
		var url = this.base_url_uearn + "/auth?source_of_login=WEB&";
		url += "email=" + obj.email + "&password=" + obj.password + "&login_method=" + obj.login_method
		return this.http.get(url);
	}
	approveAllApi(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/payment/payment_request_toall`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	saveContactFormApi(obj: any) {
		//debugger;
		let api = '/new_contactus';
		let url = "https://dev.smarter-biz.com" + api;
		return this.http.post(url, obj);
	}
	getContacts1(obj: any) {
		let api = '/new_contactus';
		let url = "https://www.contiinex.com" + api;// "https://dev.smarter-biz.com" + api;
		return this.http.post(url, obj);
	}
	getContacts(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/dashboard/getcontacts'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	uploadContacts(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/dashboard/uploadcontacts'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	resetPassword(obj: any) {
		// this.base_url = this.getprotocal_from_url();
		let api = '/reset_password';
		let url = this.base_url + api;
		return this.http.post(url, obj);
	}
	processTranscriptionExternalAinex(obj: any) {
		this.base_url = this.getprotocal_from_url();
		const api = "/stt"
		var url = (this.base_url_ainex) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	bulkUploadFaceCaptureAinexServer(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/face/capture';
		let url = this.base_url_ainex + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	bulkUploadFaceConfirmAinexServer() {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/face/confirm';
		let url = this.base_url_ainex + this.base_url_version + api;
		return this.http.get(url);
	}
	bulkUploadFaceRecognizeAinexServer(obj: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/face/recognize';
		let url = this.base_url_ainex + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getInfoCustomer(server_url: string = "", user_id: any, customerNumber: any, transactionid: any) {
		let api = `/ivrcustomer?user_id=${user_id}&transactionid=${transactionid}&customer_number=${customerNumber}`;
		let url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url);
	}

	getDesktopNotification(obj, api_end) {
		let api = `/getNotificationList?user_id=${obj.userid}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url);
	}

	getLastThreeCallRecording(obj, api_end) {
		let api = `/cmail_mails/getlastcalls?user_id=${obj.userid}&number=${obj.number}&count=${obj.count}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url);
	}

	readNotification(obj: any) {
		//debugger;
		const api = '/updateNotifications';
		let url = this.base_url + this.base_url_version_mobile + api;
		console.log(obj);
		return this.http.post(url, obj);
	}

	callmerge(obj: any, server_url: string = "") {
		let api = '/callMerge';
		let url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api;
		console.log(obj);
		return this.http.post(url, obj);
	}
	calltransfer(obj: any, server_url: string = "") {
		let api = '/callTransfer';
		let url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api;
		console.log(obj);
		return this.http.post(url, obj);
	}
	contactListGetObj: any = [];
	sendApiContactList(obj: any) {
		this.contactListGetObj = obj;
		return obj;
	}
	getApiContactListObj() {
		return this.contactListGetObj;
	}
	updateTrainingAttachFiles(obj: any) {
		// POST API - https://youearn.in/api/v1/bulkuploadnew
		let api = '/training/update_session_files';
		let url = this.base_url + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	// sendTranscriptionEmail(obj: any) {
	// 	// POST API - https://youearn.in/api/v1/bulkuploadnew
	// 	let api = '/sendtransmission_email';
	// 	let url = this.base_url + api;
	// 	return this.http.post(url, obj);
	// }
	commonevenTransfer(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/common/event/transfer';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getcenteralgroupid(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/get_central_details?role=${obj.role}&role_id=${obj.role_id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	// getUserRelationCentral(){
	// 	let api = `/get_central_details`;
	// 	let url = this.base_url + this.base_url_version + api;
	// 	return this.http.get(url);
	// }
	getUserRelationCentral(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/getRoleDetails`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getalluser(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/getAllUsers?where_gid=${obj.where_gid}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getGenericCampaignsfromCampaign_id(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/getGenericCampaignData';
		api += '?campaign_id=' + obj.campaign_id;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getcentralCampaignData(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api;
		if (obj.new_format) {
			//let api = `/centralCampaignDataRecord?start_time=${obj.start_date}&end_time=${obj.end_date}`
			api = `/centralCampaignDataRecord?start_time=${obj.start_date}&end_time=${obj.end_date}&new_format=${obj.new_format}`
		} else {
			api = `/centralCampaignDataRecord?start_time=${obj.start_date}&end_time=${obj.end_date}&local_groups=${obj.local_groups}`
		}

		// if(obj.local_groups){
		// 	api = `/centralCampaignDataRecord?start_time=${obj.start_date}&end_time=${obj.end_date}&local_groups=${obj.local_groups}`
		// }else{
		// 		api = `/centralCampaignDataRecord?start_time=${obj.start_date}&end_time=${obj.end_date}`
		// }
		//console.log(api)
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getsbiagentlist(api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/get_all_agents'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getlocalCamapignsAPI(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/campaignDataDetails
		let api;
		if (obj.start_date) {
			//let api = `/centralCampaignDataRecord?start_time=${obj.start_date}&end_time=${obj.end_date}`
			api = `/campaignDataDetails?start_time=${obj.start_date}&end_time=${obj.end_date}&campaign_type=${obj.campaign_type}`
		} else {
			api = `/campaignDataDetails?index==${obj.index}`
		}
		//let api = `/campaignDataDetails?index=10`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	DownloadMasterCallStatusReport(obj: any, api_end: any, hcd: boolean) {
		//debugger;
		this.base_url = this.getprotocal_from_url();
		let api;
		// if(obj.master_campaignname == "SBIC_STP")
		if (obj.incallscreen == "STP" && obj.user_id == 17621 || obj.incallscreen == "STP" && obj.user_id == 11469 || obj.incallscreen == "STP" && obj.user_id == 44517 || obj.incallscreen == "STP" && obj.user_id == 11911 || obj.incallscreen == "STP" && obj.user_id == 45484) {
			api = `/master_call_status_report?master_campaign=${obj.master_campaignname}`;
		}
		if (obj.incallscreen == "MULTICARD" && obj.user_id == 17621 || obj.incallscreen == "MULTICARD" && obj.user_id == 11469 || obj.incallscreen == "MULTICARD" && obj.user_id == 36510 || obj.incallscreen == "MULTICARD" && obj.user_id == 36037) {
			api = `/master_call_status_report?master_campaign=${obj.master_campaignname}`;
		}
		else {
			if (hcd) {
				api = `/master_call_status_report?master_campaign=${obj.master_campaignname}&hcd=${hcd}`;
			} else {
				api = `/master_call_status_report?master_campaign=${obj.master_campaignname}`;
			}
			///api = `/master_call_status_report?master_campaign=${obj.master_campaignname}&hcd=true`;
		}
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	DownloadMasterCallStatusReportPostRequest(obj: any, api_end: any, hcd: boolean) {
		//debugger;
		this.base_url = this.getprotocal_from_url();
		let api;
		// if(obj.master_campaignname == "SBIC_STP")
		if (obj.incallscreen == "STP" && obj.user_id == 17621 || obj.incallscreen == "STP" && obj.user_id == 11469 || obj.incallscreen == "STP" && obj.user_id == 44517 || obj.incallscreen == "STP" && obj.user_id == 11911 || obj.incallscreen == "STP" && obj.user_id == 45484) {
			api = `/master_call_status_report?master_campaign=${obj.master_campaignname}`;
		}
		if (obj.incallscreen == "MULTICARD" && obj.user_id == 17621 || obj.incallscreen == "MULTICARD" && obj.user_id == 11469 || obj.incallscreen == "MULTICARD" && obj.user_id == 36510 || obj.incallscreen == "MULTICARD" && obj.user_id == 36037) {
			api = `/master_call_status_report?master_campaign=${obj.master_campaignname}`;
		}
		else {
			if (obj.hcd) {
				api = `/master_call_status_report?master_campaign=${obj.master_campaignname}&hcd=${obj.hcd}`;
			} else {
				api = `/master_call_status_report?master_campaign=${obj.master_campaignname}`;
			}
			///api = `/master_call_status_report?master_campaign=${obj.master_campaignname}&hcd=true`;
		}
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	DownloadMasterCampaignReport(obj: any, api_end: any, hcd: boolean) {
		//debugger;
		this.base_url = this.getprotocal_from_url();
		let api;
		//console.log(JSON.stringify(obj));
		if (obj.incallscreen == "STP" && obj.user_id == 17621 || obj.incallscreen == "STP" && obj.user_id == 11469 || obj.incallscreen == "STP" && obj.user_id == 44517 || obj.incallscreen == "STP" && obj.user_id == 11911 || obj.incallscreen == "STP" && obj.user_id == 45484) {
			api = `/master_campaign_report?master_campaign=${obj.master_campaignname}`;
		}
		if (obj.incallscreen == "MULTICARD" && obj.user_id == 17621 || obj.incallscreen == "MULTICARD" && obj.user_id == 11469 || obj.incallscreen == "MULTICARD" && obj.user_id == 36510 || obj.incallscreen == "MULTICARD" && obj.user_id == 36037) {
			api = `/master_campaign_report?master_campaign=${obj.master_campaignname}`;
		}
		else {
			if (hcd) {
				api = `/master_campaign_report?master_campaign=${obj.master_campaignname}&hcd=${hcd}`;
			} else {
				api = `/master_campaign_report?master_campaign=${obj.master_campaignname}`;
			}

		}
		//console.log(api);
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		console.log(url);
		return this.http.get(url);
	}
	DownloadMasterCampaignReportPOSTRequest(obj: any, api_end: any, hcd: boolean) {
		//debugger;
		this.base_url = this.getprotocal_from_url();
		let api;
		//console.log(JSON.stringify(obj));
		if (obj.incallscreen == "STP" && obj.user_id == 17621 || obj.incallscreen == "STP" && obj.user_id == 11469 || obj.incallscreen == "STP" && obj.user_id == 44517 || obj.incallscreen == "STP" && obj.user_id == 11911 || obj.incallscreen == "STP" && obj.user_id == 45484) {
			api = `/master_campaign_report?master_campaign=${obj.master_campaignname}`;
		}
		if (obj.incallscreen == "MULTICARD" && obj.user_id == 17621 || obj.incallscreen == "MULTICARD" && obj.user_id == 11469 || obj.incallscreen == "MULTICARD" && obj.user_id == 36510 || obj.incallscreen == "MULTICARD" && obj.user_id == 36037) {
			api = `/master_campaign_report?master_campaign=${obj.master_campaignname}`;
		}
		else {
			if (hcd) {
				api = `/master_campaign_report?master_campaign=${obj.master_campaignname}&hcd=${hcd}`;
			} else {
				api = `/master_campaign_report?master_campaign=${obj.master_campaignname}`;
			}

		}
		//console.log(api);
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		console.log(url);
		return this.http.post(url, obj);
	}
	Work_Mode_Change(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/common/changeworkoption'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	work_mode_Change_bulk(obj: FormData, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/common/changeWorkoption/upload'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	_defaultProductVal: any;
	sendDefaultProductValue(val: any) {
		this._defaultProductVal = val;
		return this._defaultProductVal;
	}
	getDefaultProductValue() {
		return this._defaultProductVal;
	}
	updateWabaProfile(customer, obj: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/waba/dashboard/update_profile/' + customer;
		let url = this.base_url_comnex + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getprotocal_from_url() {
		let _protocal = location.protocol;
		// let url = 'http://localhost:3000'
		let url = _protocal + '//www.contiinex.com';
		return (url);
	}

	// comnex
	getWabaProfile(customer, api_end: any) {
		let api = '/waba/dashboard/get_profile/' + customer;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	businessProfileUploadLogo(customer, fileToUpload: File, api_end: any) {
		let api = '/waba/profile/upload_logo/' + customer;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		const formData: FormData = new FormData();
		formData.append('image', fileToUpload, fileToUpload.name);
		return this.http.post(url, formData);
	}

	getWabaTemplates(customer, api_end: any) {
		let api = '/waba/dashboard/get_templates/' + customer;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	postCampaignCreateDetails(obj, bearerToken, agentId, api_end: any) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': bearerToken
		});
		let options = { headers: headers };
		let api = `/waba/dashboard/${agentId}/Upload_Campaign`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj, options);
	}
	campaignCreateUploadDocumentExcel(obj: FormData, api_end: any) {
		let api = '/events.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	createMsgTemplateUploadImage(obj: FormData, api_end: any) {
		let api = '/chat_events.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	createMsgTemplateUploadVideo(obj: FormData, api_end: any) {
		let api = '/chat_events.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	createMsgTemplateUploadDocument(obj: FormData, api_end: any) {
		let api = '/chat_events.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getCampaignListDetails(waNumber, agentId, api_end: any) {
		let api = `/waba/dashboard/${agentId}/Get_Campaigns?wa_number=${waNumber}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getCampaignDownloadReport(obj, waNumber, agentID, api_end: any) {
		let api = `/waba/dashboard/${agentID}/Get_Campaign_Report?wa_number=${waNumber}&campaign_id=${obj.id}`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	postCreateMeaasgeTemplate(obj, bearerToken, api_end: any) {
		let api = 'https://youearn.in/gateway/WA/create_template';
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': bearerToken
		});
		let options = { headers: headers };
		let url = api;
		return this.http.post(url, obj, options);
	}

	postModifyMeaasgeTemplate(obj, bearerToken, api_end: any) {
		//debugger;
		let api = ' https://youearn.in/gateway/WA/update_template';
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': bearerToken
		});
		let options = { headers: headers };
		let url = api;
		return this.http.post(url, obj, options);
	}
	getLanguagesForCreateTemplate() {
		let api = 'https://youearn.in/gateway/WA/languages';
		let url = api;
		return this.http.get(url);
	}
	getCategoriesForCreateTemplate() {
		let api = 'https://youearn.in/gateway/WA/categories';
		let url = api;
		return this.http.get(url);
	}
	getProjectReport(customer, project, start_data: any = "", end_date: any = "", api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/reports/get_project_report/" + customer;

		if (start_data && end_date) {
			url = url + `?start_date=${start_data}&end_date=${end_date}`;
		}
		if (project && project != 'undefined' && project != 'allprojects') {
			url = url + "&prj=" + project
		}
		return this.http.get(url);
	}
	getChannelReportIVR(customer, project, start_data: any = "", end_date: any = "", api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/reports/get_ivr_report/" + customer + "?prj=" + project;
		if (start_data && end_date) {
			url = url + `&start_date=${start_data}&end_date=${end_date}`;
		}
		return this.http.get(url);
	}
	getChannelReportWA1(customer, project, start_data: any = "", end_date: any = "", sender_phones: any = "", receiver_phones: any = "", api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/reports/get_wa_report/" + customer + "?prj=" + project;
		if (start_data && end_date) {
			url = url + `&start_date=${start_data}&end_date=${end_date}`;
		}
		if (sender_phones) {
			url = url + `&requested_phones=${sender_phones}`;
		}
		if (receiver_phones) {
			url = url + `&receiver_phones=${receiver_phones}`;
		}
		return this.http.get(url);
	}
	getChannelReportSMS(customer, project, start_data: any = "", end_date: any = "", api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/reports/get_sms_report/" + customer + "?prj=" + project;
		if (start_data && end_date) {
			url = url + `&start_date=${start_data}&end_date=${end_date}`;
		}

		return this.http.get(url);
	}
	getWABADashboardApi1(obj, sender_phones: any = "", api_end: any) {
		//	debugger;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/get_wa_activities/" + obj.customer + "?prj=" + obj.project + "&view_type=" + obj.view_type + "&start_date=" + obj.startDate + "&end_date=" + obj.endDate;

		if (sender_phones) {
			url = url + `&requested_phones=${sender_phones}`;
		}

		return this.http.get(url);
	}

	getWaDashboard1(customer, project, time_range, sender_phones: any = "", api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/get_wa_activities/" + customer + "?prj=" + project + "&view_type=" + time_range;
		if (sender_phones) {
			url = url + `&requested_phones=${sender_phones}`;
		}
		return this.http.get(url);
	}
	getIvrDashboard(customer, project, time_range, api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/get_ivr_activities/" + customer + "?prj=" + project + "&view_type=" + time_range;
		return this.http.get(url);
	}

	getCjActivitiesDashboard(customer, project, time_range, start_date, end_date, api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/get_project_activities/" + customer + "?prj=" + project + "&view_type=" + time_range;

		if (start_date && end_date) {
			url = url + `&start_date=${start_date}&end_date=${end_date}`;
		}
		return this.http.get(url);


	}
	getFullTeamData1(api_end: any) {
		let api = "/full_group_details";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	getWishperToBerge1(obj: any, api_end: any) {
		let api = '/whisperToBarge ';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getBergeToWishper1(obj: any, api_end: any) {
		let api = '/bargeToWhisper';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getBerge1(obj: any, api_end: any) {
		let api = '/addParticipant';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	downloadAuditOutboundReport1(startDate, endDate, api_end: any) {
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/audit_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	downloadReportFromUrl1(url: string, api_end: any, no_host?: boolean) {
		// Download the report from url;
		let loc_url = url;
		if (no_host) {
			loc_url = (api_end ? api_end : this.base_url) + url;
		}

		let promise = new Promise((resolve, reject) => {
			this.http.get(loc_url, { observe: 'response' as 'response', responseType: 'blob' }).subscribe(
				(res) => {
					try {
						let content_type = 'application/octet-stream';
						let file_name = loc_url.split('/').pop()
						if (res.headers.get('content-type')) {
							content_type = res.headers.get("content-type");
						}
						let body = res.body;
						let blob: any = new Blob([body], { type: content_type });
						let url = window.URL.createObjectURL(blob);
						var anchor = document.createElement("a");
						anchor.download = file_name;
						anchor.href = url;

						anchor.click();
						resolve(null);
					} catch (e) {
						reject();
					}
				}
			)
		})
		return promise;
	}

	downloadCallStatusOutboundReport1(obj, api_end: any) {
		// POST -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/cmail_mails';

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		console.log("---5--")
		return this.http.post(url, obj);
	}
	genericCallDataDetails1(obj: any, api_end: any) {
		//POST API - https://youearn.in/api/v1/getGenericCallsData
		let api = '/getGenericCallsData';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGenericTimersData1(obj: any, api_end: any) {
		// GET API - https://youearn.in/api/v1/common/timer_counts
		let api = '/common/timer_counts';
		/* api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		if (obj.isHistory) {
			api += '&isHistory=true';
		} */
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGenericCustomerData1(obj: any, api_end: any) {
		let api = '/getGenericCustomerData';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getGenericChannelsData1(obj: any, api_end: any) {
		//POST API - https://youearn.in/api/v1/common/channelSummary
		let api = '/common/channelSummary';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getAllGroupStatus1(groupId: any, api_end: any) {
		let api = '/getAllGroupStatus?jsonresponse=1&group=' + groupId;
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.get(url);
	}
	pmDashboard1(obj: any, api_end: any) {
		let api = '/common/dashboard';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getFullTeamData2(api_end: any) {
		// GET API - https://youearn.in/api/v1/full_group_details
		let api = "/full_group_details";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	pmDatewiseSlots1(obj: any, api_end: any) {
		let api = '/getDatewiseSlots';
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;

		return this.http.get(url);
	}
	pmDashboardInbound1(obj: any, api_end: any) {
		// POST API - https://youearn.in/api/v1/common/inbounddashboard
		let api = '/common/inbounddashboard';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	downloadAgentLoginInboundReport1(startDate, endDate, api_end: any) {
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/common/agent_login_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	downloadAgentActivityInboundReport1(startDate, endDate, api_end: any) {
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/common/agent_activity_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	downloadCallStatusInboundReport1(startDate, endDate, api_end: any) {
		// GET -- https://youearn.in/api/v1/cmail_mails?incsales=1&userset=Team&start_date=2020-07-23T02:23:00&end_date=2020-07-23T14:25:00&download=true&offset=330&unique_data=true&campaign=omkar%20test
		let api = '/common/inbound_call_report?start_date=' + startDate + '&end_date=' + endDate;

		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}

	getInboundIvrDashboard(customer, project, time_range, api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/get_inbound_ivr_activities/" + customer + "?prj=" + project + "&view_type=" + time_range;
		return this.http.get(url);
	}
	getSmsDashboard(customer, project, time_range, api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/get_sms_activities/" + customer + "?prj=" + project + "&view_type=" + time_range;
		return this.http.get(url);
	}
	getWidgetDashboard(obj, api_end: any) {
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/get_widget_activities/" + obj.customer + "?prj=" + obj.project + "&activity_type=" + obj.activity_type + "&sales_type=" + obj.sales_type + "&view_type=" + obj.view_type + "&start_date=" + obj.startDate + "&end_date=" + obj.endDate;
		return this.http.get(url);
	}
	getSopifyAgentTeamList(customer, api_end: any) {
		let api = '/sox/projects/get_team/' + customer;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	deleteAddShopifyTeam(obj, api_end: any) {
		let api = '/cmail_users/' + obj.id + '.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.put(url, obj);
	}
	editAddShopifyTeam(obj, api_end: any) {
		let api = '/cmail_users/' + obj.id + '.json';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.put(url, obj);
	}
	saveJoin1(obj: any, api_end: any) {
		let api = '/cmail_users.json?ver=2';
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	createWidgetClient(obj, api_end: any) {
		let api = '/settings/create';
		let url = (api_end ? api_end : this.base_url) + api;
		return this.http.post(url, obj);
	}
	updateSelectChannel(obj, api_end: any) {
		let api = '/cmail_users/updateuser';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	getWidgetClientSettings(company, api_end: any) {
		//debugger;
		let api = '/settings/select';
		let url = (api_end ? api_end : this.base_url) + api + "?name=widget_options" + "&company=" + company;
		return this.http.get(url);
	}
	getpreItemsData(customer_name: any, project_name: any, api_end: any) {
		//	let api = `/sox/call_flow/fetch/collections?customer=${customer_name}&prj=${project_name}`;
		let url = `https://api.contiinex.com/api/v1/sox/call_flow/fetch/collections?customer=${customer_name}&prj=${project_name}`
		//	let url = this.base_url + this.base_url_version + api;
		return this.http.get(url)
	}
	getFunnelDetailView(customer, project, time_range, start_date, end_date, api_end: any) {
		//debugger;
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/funnel_detail/" + customer + "?prj=" + project + "&view_type=" + time_range;
		if (start_date && end_date) {
			url = url + `&start_date=${start_date}&end_date=${end_date}`;
		}
		return this.http.get(url);
	}

	getFunnelDashboard(customer, project, time_range, start_date, end_date, api_end: any) {
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + "/sox/dashboard/funnel/" + customer + "?prj=" + project + "&view_type=" + time_range;
		if (start_date && end_date && time_range == 'Custom') {
			url = url + `&start_date=${start_date}&end_date=${end_date}`;
		}
		return this.http.get(url);
	}
	getlocalCampaignList(obj: any, api_end: any) {

		this.base_url = this.getprotocal_from_url();
		// POST API - https://youearn.in/api/v1/campaignDataDetails
		let api;
		if (obj.start_date && obj.local_groups) {
			//let api = `/centralCampaignDataRecord?start_time=${obj.start_date}&end_time=${obj.end_date}`
			api = `/campaignDataList?start_date=${obj.start_date}&end_date=${obj.end_date}&local_groups=${obj.local_groups}`
		} else if (obj.start_date) {
			api = `/campaignDataList?start_date=${obj.start_date}&end_date=${obj.end_date}`
		}
		//  {
		// 	api = `/campaignDataList?index==${obj.index}`
		// }
		//let api = `/campaignDataDetails?index=10`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		// let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		// let url = this.base_url + this.base_url_version + api;
		// console.log('base url', url)
		return this.http.get(url);
	}
	deleteCreateMessageTemplate(obj, waNumber, bearerToken, api_end: any) {
		let api = `https://youearn.in/gateway/wa/delete_template?wa_number=${waNumber}&template_name=${obj.name}`;

		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': bearerToken
		});
		let options = { headers: headers };
		let url = api;
		return this.http.delete(url, options);
	}
	getGroupwiseUser(obj: any, api_end: any) {

		this.base_url = this.getprotocal_from_url();
		let api;
		api = `/getusersofgroup?group_id=${obj.group_id}`
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	_customerDetailsList: any;
	sendCustomerDetailsList(obj: any) {
		this._customerDetailsList = [];
		this._customerDetailsList = obj;
		return obj;

	}
	getCustomerDetailsList() {
		if (this._customerDetailsList && this._customerDetailsList.length > 0) {
			return this._customerDetailsList;
		}
	}
	_customerRowData: any;
	sendRowContactList(obj: any) {
		this._customerRowData = [];
		this._customerRowData = obj;
		return obj;

	}
	getRowContactList() {
		if (this._customerRowData) {
			return this._customerRowData;
		}
	}
	sendPushNotification(obj: any, token: any, api_end: any) {
		const api = "/sendPushNotification"
		var url = (api_end ? api_end : this.base_url) + this.base_url_version + api + `?access_token=${token}`;
		return this.http.post(url, obj);

	}
	updateNotificationsReadAgent(obj: any, bearerToken: any, api_end: any) {
		const api = "/updateNotifications"
		var url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': bearerToken
		});
		let options = { headers: headers };
		return this.http.post(url, obj, options);
	}
	getcmail_usersby_id(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		const api = `/cmail_users/${obj.id}.json`
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	// Settings API
	get_groupid_settingsAPI(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//this.base_url= this._service.get_API_End_Point();
		// GET API - https://youearn.in/api/v1/settings/stages
		let api = `/settings/stages?groupid=${obj.groupid}`;
		// let url = this.base_url + this.base_url_version + api;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url);
	}
	timer_count_all_asm(obj: any, api_end: any) {
		let api = '/common/timer_counts_asm_wise';
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	saveContactsWhatsapp1(obj: any, server_url: string = "", api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = `/chat/send_message`;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		//var url = (server_url ? server_url : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	Soft_login_tcl(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//let api = `https://app.ceex.ai/softdial/phonetcl/`;
		let url = 'https://app.ceex.ai/softdial/repository/tcl/api/auth/LogIn'                   //`https://app.ceex.ai/softdial/phonetcl`
		//(api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	Disconnect_tts_c2c_API(obj: any, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		//let api = '/tts/Disconnect_C2C_Call'
		let api = '/siptogsmdisconnect'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	TTS_c2c_API(obj: any, api_end: any) {
		let api = '/tts/c2c' //change the url from manual_dial_customer to tts/c2c
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.post(url, obj);
	}
	LI_list_Contacted(obj, api_end: any) {
		this.base_url = this.getprotocal_from_url();
		let api = '/LC/common/event/list_contacted';
		api += '?start_date=' + obj.start_time;
		api += '&end_date=' + obj.end_time;
		api += '&id_group=' + obj.id_group;
		api += '&user_id=' + obj.user_id;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
		return this.http.get(url, obj);
	}
	LC_update_API(obj: any, api_end: any) {
		let api = '/LC_status_update'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	check_customer_details(obj: any, api_end: any) {
		let api = '/Customer_details_check'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}
	download_LC_Report(obj: any, api_end: any) {
		let api = '/LC/common/event/report_download'
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}


	//Central call status report
	call_status_report(obj, api_end: any) {
		let api = '/cmail_mails_centers'
		api += '?start_date=' + obj.start_date;
		api += '&end_date=' + obj.end_date;
		api += '&incsales= 1';
		api += '&userset=Team';
		api += '&download=true';
		api += '&offset=330';
		api += '&campaign=' + obj.end_time;
		api += '&hcd=true';
		api += '&required_centers=' + obj.agents_list;
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.get(url, obj);
	}
	// downloadReportLC(obj:any, api_end: any){
	// 	let api ="/LC/common/event/bulk-report-download-update";
	// 	let url = (api_end ? api_end : this.base_url) + this.base_url_version_mobile + api;
	// 	return this.http.post(url, obj);


	// }
	saveUploadLC(obj: any, api_end: any) {
		//form 'attachment=@"/C:/Users/Admin/Downloads/Download_LC_Report_67.xlsx"'

		let api = "/LC/Bulk-push/status-update";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	send_sbi_sms(obj:any, api_end:any){
		let api = "/send_message";
		let url = (api_end ? api_end : this.base_url) + this.base_url_version + api;
		return this.http.post(url, obj);
	}

	/* API Rules */
	get_supervisor_api_rules(api_end:string){
		const api = "/settings/api_rules";
		const url = api_end + this.base_url_version + api;
		return this.http.get(url);
	}

	get_agents_list_for_supervisor(incall_screen:string, supervisor_id:number, api_end: string){
		const api = `${api_end}${this.base_url_version}/settings/api_rules_supervisor?incall_screen=${incall_screen}&supervisor_id=${supervisor_id}`;
		return this.http.get(api);
	}

	post_agents_list_for_supervisor(obj, api_end:string){
		const api = `${api_end}${this.base_url_version}/settings/api_rules_supervisor`;
		return this.http.post(api, obj)
	}
	getDownloadSpeed(api_end:string){
		const api = `${api_end}/speedtest`;
		return this.http.get(api, { observe: 'response' });
	}
}


