import { Component, OnInit, ElementRef, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router'
import * as moment from 'moment';
import * as _ from 'underscore';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { filter } from 'rxjs/operators';

import { CampaignDialogComponent } from './campaign/campaign-dialog/campaign-dialog.component';
import { DownloadAttachmentComponent } from './download-attachment/download-attachment.component';
import { CampaignAgentsComponent } from './campaign-agents/campaign-agents.component';
import { DashboardAddmoreDialogComponent } from '../dashboard-addmore-dialog/dashboard-addmore-dialog.component';
import { DashboardAddagentComponent } from '../dashboard-addagent/dashboard-addagent.component';
import { ServicesService } from '../dashboard/services.service';
import { CampaignService } from '../campaign.service';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

import { Utils } from '../utils';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface campaignTableRow {
	'sl_no': number,
	'campaign_name': string,
	'campaign_status': boolean,
	'campaign_created': string,
	'data_uploaded': number,
	'data_duplicate': number,
	'errors': number,
	'data_added': number,
	'contacted': number,
	'agency_name': string,
	'downloadReports': any
}

@Component({
	selector: 'app-dashboardv2',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	providers: [Title]
})
export class DashboardComponent implements OnInit {

	// @ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('_searchValue') _searchValue: ElementRef;

	currentTitle: string = 'campaignV2';
	selectAgencies: string = '';
	today = moment().format('DD-MMM-YYYY');
	showCampaign: boolean = true;
	showRecruiter: boolean = false;
	showHiring: boolean = false;
	showLoader: boolean = false;
	isCampbtnDisable: boolean = false;
	user: any = this.myservice.get_loggedin_user();
	current_campaigns = null;
	show_details: boolean = false;
	currentRoute: string = "";
	jsonTemp: string = "";
	months_filter: any = this.utils.getCampaignMonthDropdown();
	selected_month = this.months_filter[0];
	offset: boolean;
	show_bulk_selection_banner: boolean = false;
	bulk_camapigns_switch: boolean = false;
	bulk_all_campaigns_selected: boolean = false;


	displayedColumnsCampaigan: string[] = ['sl_no', 'campaign_name', 'actionViewMore', 'campaign_owner', 'work_type', 'campaign_status', 'last_updated', 'campaign_created',
		'data_uploaded', 'agents_count', 'data_duplicate', 'errors', 'data_added', 'data_dialled', 'contacted', 'rechurn', 'downloadReports']; //'data_source',

	public dataSourceCampaign = new MatTableDataSource();

	summary_default = {
		'total': {
			'total': 0,
			'data': 0,
			'called': 0,
			'to_call': 0,
			'data_percent': 100,
			'called_percent': 0,
			'to_call_percent': 0
		},
		'active': {
			'total': 0,
			'data': 0,
			'called': 0,
			'to_call': 0,
			'data_percent': 0,
			'called_percent': 0,
			'to_call_percent': 0
		},
		'inactive': {
			'total': 0,
			'data': 0,
			'called': 0,
			'to_call': 0,
			'data_percent': 0,
			'called_percent': 0,
			'to_call_percent': 0
		},
		'data_source': {
			'others': {
				'total': 0,
				'dialled': 0,
				'data': 0,
				'conversion': '0%',
				'called_percent': '0'
			}
		},
		'channels': {
			"Email": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"SMS": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"Whatsapp": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"Voice Bot": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"Agent": {
				"clicks": 0,
				"throughput": "0.00%"
			}
		}
	}

	summary = JSON.parse(JSON.stringify(this.summary_default));
	currentCamapigns: any[] = [];
	messageAlert: any = "";
	showHide_messageAlert: boolean = false;
	api_end_point: any;
	list_group = [];
	// totalRows = 0;
	// pageSize = 10;
	// currentPage = 0;
	// pageSizeOptions: number[] = [10, 25, 50];
	@ViewChild(MatPaginator) paginator: MatPaginator;

	ngAfterViewInit() {
		this.dataSourceCampaign.paginator = this.paginator;
	}
	constructor(
		private dialog: MatDialog,
		private dashService: ServicesService,
		private titleService: Title,
		private API: ApiService,
		private utils: Utils,
		private myservice: MyserviceService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private _snackBar: MatSnackBar,
		public campaign_service: CampaignService
	) {
		console.log('construcor starts');
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				// console.log('subscribe event:', event);
				this.currentRoute = event.url;
				if (this.currentRoute == '/campaignV2/details') {
					this.dashService.getSelectedCamapign().subscribe(d => {
						//console.log('selected campaign data', d);
						if (!d) {
							this.show_details = false;
							this.router.navigate(['./campaignV2']);
							// this.refreshCampaignData();
						} else {
							this.show_details = true;
						}
					})
				}
				if (this.currentRoute == '/campaignV2') {
					this.dashService.SELECTED_CAMPAIGN.next(null);
					this.show_details = false;
				}
				// console.log(this.currentRoute)
			});
	}

	ngOnInit() {
		console.log('dashboard component starts')
		// var managedgroups = JSON.parse(this.user.managedgroups)
		// //debugger;
		// //console.log('dashboard component starts '+managedgroups);
		// var group_data = managedgroups.values;
		// console.log('dashboard component starts '+group_data);

		// for(var i=0; i<group_data.length; i++)
		// {
		// 	if(group_data[i].groupid)
		// 	{
		// 		this.list_group.push(group_data[i].groupid);
		// 	}
		// }
		// console.log('-----'+JSON.stringify(this.list_group));
		// console.log('dashboard component starts')
		this.show_details = false;
		this.titleService.setTitle('Contiinex | Campaign Dashboard');
		this.dataSourceCampaign.paginator = this.paginator;
		this.api_end_point = this.myservice.get_API_End_Point();
		this.refreshCampaignData();
	}

	get_decimal_val(val:number | string){
		try{
			return parseFloat(val.toString()).toFixed(1);
		}catch(e){
			val = 0;
			return parseFloat(val.toString()).toFixed(1);
		}
	}

	getTextMerge(val1, val2) {
		return `${val1} - ${val2}`;
	}

	change_selectedMonth(e) {
		// console.log('event', e);
		this.selected_month = _.find(this.months_filter, (obj) => {
			return obj.value == e.value;
		})
		// console.log('on select change', this.selected_month);
	}

	getJsonKeys(obj) {
		return Object.keys(obj);
	}

	numericThresholdBycolor(val) {
		let color = 'red';
		if (val > 30) {
			color = 'lightgreen';
		}
		return color;
	}

	check_val_type(val) {
		return typeof val;
	}

	createCampaignTable = function (data: any) {
		let arr = [];
		// debugger;
		for (var i = 0; i < data.length; i++) {
			let obj = new Object();
			// console.log('printing obj',obj)
			let loc_obj = data[i];
			let details = {};
			let d_t_c = 0;
			if (loc_obj.details && loc_obj.details.length) {
				details = JSON.parse(loc_obj.details);
			}
			let agents_list = [];
			if (loc_obj.agents_list) {
				if (typeof loc_obj.agents_list == 'string') {
					let p = JSON.parse(loc_obj.agents_list);
					// console.log('printing agents list', p);
					agents_list = p[this.user.groupid];
				}
			}

			obj['sl_no'] = i + 1;
			obj['index_val'] = i;
			obj['id'] = loc_obj['id'];
			obj['campaign_name'] = loc_obj['campaign'];
			if (loc_obj['status'] === 'assigned') {
				obj['campaign_status'] = !!loc_obj['active_status'];
			} else {
				obj['campaign_status'] = loc_obj['status'];
			}
			//obj['campaign_owner'] = loc_obj['name'];
			obj['campaign_created'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['created_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY');
			obj['last_updated'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM hh:mm a');
			obj['data_uploaded'] = loc_obj['Total Leads'];
			obj['data_duplicate'] = loc_obj['Total Repeat Drops'];
			obj['errors'] = loc_obj['Total Errors'];
			obj['data_added'] = this.utils.getValue(loc_obj['Total Pushed'], 0);
			d_t_c = obj['data_added'];
			if (loc_obj['data_not_used']) {
				d_t_c = this.utils.getValue(loc_obj['data_not_used'], 0);
			}
			obj['data_to_call'] = this.utils.getValue(d_t_c, 0)
			obj['contacted'] = this.utils.getDifference(obj['data_added'], d_t_c, 0);
			obj['agency_type'] = this.utils.getValue(loc_obj['master_campaign'], '');
			obj['url'] = loc_obj.url;
			obj['created_at'] = loc_obj.created_at;
			obj['agency_name'] = loc_obj.others;
			obj['data_source'] = 'others';
			if (loc_obj.master_campaign && loc_obj.master_campaign.length) {
				obj['data_source'] = loc_obj.master_campaign;
			}
			obj['agents_count'] = 0;
			if (agents_list && agents_list.length) {
				obj['agents_count'] = agents_list.length;
			}
			arr.push(obj)
		}
		// console.log('printing current campaigns',arr)
		// console.log('login user', this.user);
		this.currentCamapigns = arr;
		// console.log('campaign list', this.currentCamapigns)
		this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
		this.dataSourceCampaign.paginator = this.paginator;
	}

	getCampaignGroupData(campaign_arr) {
		let obj = {
			'data': 0,
			'to_call': 0,
			'called': 0,
			'to_call_percent': 0,
			'called_percent': 0,
			'contacted': 0
		}

		for (var i in campaign_arr) {
			obj.data += campaign_arr[i]['data'];
			obj.to_call += campaign_arr[i]['data_to_call'];
			obj.contacted += campaign_arr[i]['contacted'];
		}
		obj.called = obj.data - obj.to_call;
		obj.called_percent = parseInt((obj.called * 100 / obj.data).toFixed(1));
		obj.to_call_percent = parseInt((100 - obj.called_percent).toFixed(1));

		return obj;
	}

	downloadDataSheet(obj: any) {
		obj.showLoader = true;
		// debugger;
		this.API.downloadReportFromUrl(obj.url, true, this.api_end_point).then(
			() => {
				obj.showLoader = false;
			}
		)
	}

	campaignStatusChange(e, element) {
		//console.log('curent status', element, e.checked);
		/* console.log('event e', e);
		console.log('curent status', prev_status, e.checked); */
		const prev_status = !e.checked;
		element.disabled = true;
		let obj = {};
		if (e.checked) {
			obj['active_list'] = [element.campaign_name];
		} else {
			obj['inactive_list'] = [element.campaign_name]
		}

		this.API.campaignSwitch(obj, this.api_end_point).subscribe((res: any) => {
			if (res.errors) {
				//	alert('Camapign Switch Failed');
				e.checked = prev_status;
				element.disabled = false;

				this.messageAlert = "Camapign Switch Failed";
				this.showHide_messageAlert = true;
				setTimeout(() => {
					this.showHide_messageAlert = false;
				}, 4000)

			} else {
				//alert('Campaign Switch Success.');

				this.refreshCampaignData();
				element.disabled = false;
				this.messageAlert = "Campaign Switch Success";
				this.showHide_messageAlert = true;
				setTimeout(() => {
					this.showHide_messageAlert = false;
				}, 4000)
			}
		})
	}

	downloadCampaignStatusReport(obj: any, with_agents?: boolean) {
		// debugger;
		let campaign_data = obj;
		campaign_data.showLoader = true;
		let loc: any = {
			'campaign_id': obj['id'],
			'offset': 330
		}
		//if (this.user.id != obj.user_id) {
		let hide_number = this.campaign_service.hide_customer_number_access();
		if (hide_number) {
			loc.hcd = true
		}
		//}
		if (with_agents) {
			loc.agents_list = this.myservice.getAllIds('user', 'id')
		}
		if ((this.user.id == obj.user_id) || this.campaign_service.having_full_access()) {
			if (loc.agents_list) delete loc.agents_list
		}
		this.API.downloadCampaignStatusReport(loc, this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				campaign_data.showLoader = false;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
						() => {
							//campaign_data.showLoader = false;
						}
					)
				} else {
					//	alert(res.errors)
					this.messageAlert = res.errors;
					this.showHide_messageAlert = true;
					setTimeout(() => {
						this.showHide_messageAlert = false;
					}, 6000)
				}
			}, (err) => {
				// debugger;
				campaign_data.showLoader = false;
			}
		)
	}

	downloadCampaignStatusReportMasterMYagents(obj: any, with_agents?: boolean) {
		let campaign_data = obj;
		let post_obj: any = {}
		post_obj.master_campaignname = obj.master_campaign
		post_obj.agents_list = this.myservice.getAllIds('user', 'id')
		let hide_number = this.campaign_service.hide_customer_number_access();
		if (hide_number) {
			post_obj.hcd = true
		}
		campaign_data.showLoader = true;
		this.API.DownloadMasterCampaignReportPOSTRequest(post_obj, this.api_end_point, false).subscribe((res: any) => {
			campaign_data.showLoader = false;
			// console.log('response campaign status', res)
			if (res.url) {
				this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
					() => {
						//campaign_data.showLoader = false;
					}
				)
			}
			if (res.errors) {
				this._snackBar.open(res.errors, 'close');
			}
		}, (err: any) => {
			campaign_data.showLoader = false;
		})
	}

	downloadCampaignStatusReportMaster(obj: any, with_agents?: boolean) {
		let campaign_data = obj;
		obj.master_campaignname = obj.master_campaign
		let hide_number = this.campaign_service.hide_customer_number_access();
		if (hide_number) {
			obj.hcd = true
		}
		if (with_agents) {
			obj.agents_list = this.myservice.getAllIds('user', 'id')
		}
		if ((this.user.id == obj.user_id) || this.campaign_service.having_full_access()) {
			if (obj.agents_list) {
				delete obj.agents_list;
			}
		}
		campaign_data.showLoader = true;
		this.API.DownloadMasterCampaignReport(obj, this.api_end_point, false).subscribe((res: any) => {
			campaign_data.showLoader = false;
			// console.log('response campaign status', res)
			if (res.url) {
				this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
					() => {
						//campaign_data.showLoader = false;
					}
				)
			}
			if (res.errors) {
				this._snackBar.open(res.errors, 'close');
			}
		}, (err: any) => {
			campaign_data.showLoader = false;
		})
	}

	downloadCallStatusReportMaster(obj: any) {
		let campaign_data = obj;
		obj.master_campaignname = obj.master_campaign
		if (this.user.id != obj.user_id) {
			let hide_number = this.campaign_service.hide_customer_number_access();
			if (hide_number) {
				obj.hcd = true
			}
		}
		campaign_data.showLoader = true;
		this.API.DownloadMasterCallStatusReport(obj, this.api_end_point, false).subscribe((res: any) => {
			campaign_data.showLoader = false;
			// console.log('response call status', res)
			if (res.url) {
				this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
					() => {
						//campaign_data.showLoader = false;
					}
				)
			}
			if (res.errors) {
				this._snackBar.open(res.errors, 'close');
			}
		}, (err: any) => {
			campaign_data.showLoader = false;
		})
	}

	downloadCallStatusReportMasterMyagents(obj: any) {
		let campaign_data = obj;
		let post_obj: any = {}
		post_obj.master_campaignname = obj.master_campaign
		let hide_number = this.campaign_service.hide_customer_number_access();
		if (hide_number) {
			post_obj.hcd = true
		}
		if ((this.user.id != obj.user_id) || !this.campaign_service.having_full_access()) {
			post_obj.agents_list = this.myservice.getAllIds('user', 'id')
		}
		campaign_data.showLoader = true;
		this.API.DownloadMasterCallStatusReportPostRequest(post_obj, this.api_end_point, false).subscribe((res: any) => {
			campaign_data.showLoader = false;
			// console.log('response call status', res)
			if (res.url) {
				this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
					() => {
						//campaign_data.showLoader = false;
					}
				)
			}
			if (res.errors) {
				this._snackBar.open(res.errors, 'close');
			}
		}, (err: any) => {
			campaign_data.showLoader = false;
		})
	}

	checkOwnership(obj) {
		if (this.user.id != obj.user_id) {
			return false;
		}
		return true;
	}

	downloadCustomerStatusReport(obj: any) {
		// debugger;
		// console.log('printing obj', obj);
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let campaign = obj.campaign_name;
		if (this.user.id != obj.user_id) {
			let hide_number = this.campaign_service.hide_customer_number_access();
			if (hide_number) {
				obj.hcd = hide_number
			}
		}

		this.API.downloadCustomerStatusReport(this.api_end_point, start_date, end_date, campaign, obj.hcd).subscribe(
			(res: any) => {
				// debugger;
				obj.showLoader = false;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
							() => {
								// obj.showLoader = false;
							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				obj.showLoader = false;
				alert('Error in the API call')
			}
		)
	}


	downloadCustomerStatusReport_withoutcallrecording(obj: any) {
		var hide_number = false;
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let campaign = obj.campaign_name;
		var hcd;
		if (this.user.id != obj.user_id) {
			let hide_number = this.campaign_service.hide_customer_number_access();
			if (hide_number) {
				hcd = true
			}
		}
		this.API.downloadCustomerStatusReportwithoutcallrecording(this.api_end_point, start_date, end_date, campaign, hcd).subscribe(
			(res: any) => {
				// debugger;
				obj.showLoader = false;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
							() => {
								// obj.showLoader = false;
							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				obj.showLoader = false;
				alert('Error in the API call')
			}
		)
	}
	downloadCallStatusReport(obj: any, unique_data?: boolean) {
		// debugger;
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let campaign = obj.campaign_name;
		if (this.user.id != obj.user_id) {
			let hide_number = this.campaign_service.hide_customer_number_access();
			// console.log('hide number', hide_number)
			if (hide_number) {
				obj.hcd = true
			}
		}

		if (unique_data) {
			obj.unique_data = unique_data
		}

		this.API.downloadCallStatusReport(start_date, end_date, campaign, this.api_end_point, obj.hcd).subscribe(
			(res: any) => {
				obj.showLoader = false;
				// debugger;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
							() => {

							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				alert('Error in the API call');
				obj.showLoader = false;
			}
		)
	}

	downloadCallStatusReportPost(obj: any, unique_data?: boolean) {
		// debugger;
		// console.log('input obj', obj)
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let post_obj: any = {
			'start_date': start_date,
			'end_date': end_date,
			'incsales': 1,
			'userset': 'Team',
			'download': 'true',
			'offset': 330,
			'campaign': obj.campaign_name,
			'agents_list': this.myservice.getAllIds('user', 'id')
		}
		if (this.user.id != obj.user_id) {
			let hide_number = this.campaign_service.hide_customer_number_access();
			// console.log('hide number', hide_number)
			if (hide_number) {
				// obj.hcd = true
				post_obj.hcd = true;
			}
		}

		if ((this.user.id == obj.user_id) || this.campaign_service.having_full_access()) {
			delete post_obj.agents_list
		}

		if (unique_data) {
			post_obj.unique_data = unique_data
		}
		// console.log('post object', post_obj)
		// return;
		this.API.downloadCallStatusReportPost(post_obj, this.api_end_point).subscribe(
			(res: any) => {
				obj.showLoader = false;
				// debugger;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url, null, this.api_end_point).then(
							() => {

							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				alert('Error in the API call');
				obj.showLoader = false;
			}
		)
	}


	downloadMasterCampaignStatusreport(obj: any) {
		//console.log('Downloading master campaign status report');
	}

	downloadMasterCampaigncallStatusreport(obj: any) {
		//console.log('Downloading master campaign call status report');
	}

	refreshCampaignData() {
		if(this.show_bulk_selection_banner){
			this.close_bulk_camapign_selection_banner();
		}

		setTimeout(() => {
			this.showLoader = true;
		})
		this.dataSourceCampaign = new MatTableDataSource([]);
		this.dataSourceCampaign.paginator = this.paginator;
		this.summary = JSON.parse(JSON.stringify(this.summary_default));
		let start_date = this.selected_month.start_date;
		let end_date = this.selected_month.end_date;
		// console.log('printing selected date', start_date, end_date);
		var reqObj = {
			// "index": 0,
			// "agents_list": this.myservice.getAllIds('user', 'email'),
			start_date,
			end_date
		}
		this.API.getChannelsCampaign(this.api_end_point)
			.subscribe(
				(res: any) => {
					if (res.errors) {
						this._searchValue.nativeElement.value = "";
					} else {
						this.summary.channels = res.success;
						this._searchValue.nativeElement.value = "";
					}
				});
		let customer_data_obj: any = { ...reqObj };
		customer_data_obj.agents_list = this.myservice.getAllIds('user', 'id');
		this.API.getGenericCustomerData(customer_data_obj, this.api_end_point)
			.subscribe(
				(res: any) => {
					if (res.errors) {

					} else {
						if (res.success) {
							this.summary.total.total = res.success.active.total_assigned + res.success.inactive.total_assigned;
							this.summary.total.data = res.success.active.total_assigned + res.success.inactive.total_assigned;
							this.summary.total.called = res.success.active.data_contacted + res.success.inactive.data_contacted;
							this.summary.total.to_call = res.success.active.data_dialled + res.success.inactive.data_dialled;

							this.summary.total.data_percent = this.summary.total.data != 0 ? parseInt((this.summary.total.data * 100 / this.summary.total.total).toFixed(1)) : 0;
							this.summary.total.called_percent = this.summary.total.called != 0 ? parseInt((this.summary.total.called * 100 / this.summary.total.total).toFixed(1)) : 0;
							this.summary.total.to_call_percent = this.summary.total.to_call != 0 ? parseInt((this.summary.total.to_call * 100 / this.summary.total.total).toFixed(1)) : 0;

							this.summary.active.total = res.success.active.total_assigned;
							this.summary.active.data = res.success.active.total_assigned;
							this.summary.active.called = res.success.active.data_contacted;
							this.summary.active.to_call = res.success.active.data_dialled;

							this.summary.active.data_percent = res.success.active.total_assigned != 0 ? parseInt((this.summary.active.data * 100 / res.success.active.total_assigned).toFixed(1)) : 0;
							this.summary.active.called_percent = this.summary.active.called != 0 ? parseInt((this.summary.active.called * 100 / this.summary.active.total).toFixed(1)) : 0;
							this.summary.active.to_call_percent = this.summary.active.to_call != 0 ? parseInt((this.summary.active.to_call * 100 / this.summary.active.total).toFixed(1)) : 0;

							this.summary.inactive.total = res.success.inactive.total_assigned;
							this.summary.inactive.data = res.success.inactive.total_assigned;
							this.summary.inactive.called = res.success.inactive.data_contacted;
							this.summary.inactive.to_call = res.success.inactive.data_dialled;;
							this.summary.inactive.data_percent = res.success.inactive.total_assigned != 0 ? parseInt((this.summary.inactive.data * 100 / res.success.inactive.total_assigned).toFixed(1)) : 0;
							this.jsonTemp = JSON.stringify(this.summary);
							this.summary.inactive.called_percent = this.summary.inactive.called != 0 ? parseInt((this.summary.inactive.called * 100 / this.summary.inactive.total).toFixed(1)) : 0;
							this.summary.inactive.to_call_percent = this.summary.inactive.to_call != 0 ? parseInt((this.summary.inactive.to_call * 100 / this.summary.inactive.total).toFixed(1)) : 0;

							if (res.success.incallscreen) {
								// this.summary.data_source = res.success.incallscreen
								for (var i in res.success.incallscreen) {
									res.success.incallscreen[i]['called_percent'] = 0
									if (res.success.incallscreen[i]['data']) {
										res.success.incallscreen[i]['called_percent'] = parseInt((res.success.incallscreen[i]['consumed'] * 100 / res.success.incallscreen[i]['data']).toFixed(1));
									}
								}
								this.summary.data_source = res.success.incallscreen
							}

						}
					}
				}, (err) => {
					//console.log(err)
				}
			)
		let campaignDetailsObj: any = { ...reqObj };
		let non_users = this.myservice.getAllIds('non-user', 'id');
		non_users.push(this.user.id);
		campaignDetailsObj.campaign_owners = non_users;
		//console.log("--usersettings--" + JSON.stringify(this.user.company_alias));
		if (this.user.company_alias == "BPCL_MANAGER") {
			campaignDetailsObj.campaign_type = 'BPCL'
		}
		else if (this.user.company_alias == "LANDMARK_MANAGER") {
			campaignDetailsObj.campaign_type = 'LANDMARK'
		}
		else if (this.user.company_alias == "APOLLO_MANAGER") {
			campaignDetailsObj.campaign_type = 'APOLLO'
		}
		else if (this.user.company_alias == "STP_MANAGER") {
			campaignDetailsObj.campaign_type = 'STP'
		}
		else if (this.user.company_alias == "MULTICARD_MANAGER") {
			campaignDetailsObj.campaign_type = 'MULTICARD'
		}
		delete campaignDetailsObj.campaign_owners; // removing campaign owners to access all campaigns.
		this.API.genericCampaignDataDetails(campaignDetailsObj, this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				this.showLoader = false;
				var camp_assigning = false;
				if (res.camapigns_list) {
					//res.camapigns_list.reverse();
					let arr = [];
					for (var i = 0; i < res.camapigns_list.length; i++) {
						let obj: any = {};
						let details = {};
						// console.log('printing obj',obj)
						let loc_obj = res.camapigns_list[i] as any;
						obj = { ...obj, ...loc_obj }
						if (loc_obj.details && loc_obj.details.length) {
							details = JSON.parse(loc_obj.details);
						}
						if (loc_obj.status == 'assigning') {
							camp_assigning = true;
						}
						let agents_list = [];
						if (loc_obj.agents_list) {
							if (typeof loc_obj.agents_list == 'string') {
								let p = JSON.parse(loc_obj.agents_list);
								// console.log('printing agents list', p);
								agents_list = p[this.user.groupid];
							}
						}

						obj['sl_no'] = i + 1;
						obj['index_val'] = i;
						obj['id'] = loc_obj['id'];
						obj['campaign_name'] = loc_obj['campaign'];
						obj['rechurn'] = loc_obj['rechurn'];
						if (loc_obj['status'] === 'assigned') {
							obj['campaign_status'] = !!loc_obj['active_status'];
						} else {
							obj['campaign_status'] = loc_obj['status'];
						}
						obj['campaign_owner'] = loc_obj['name'] || "";
						obj['agency_type'] = this.utils.getValue(loc_obj['master_campaign'], '');
						obj['campaign_created'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['created_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY');
						obj['last_updated'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM hh:mm a');
						obj['url'] = loc_obj.url;
						obj['created_at'] = loc_obj.created_at;
						if (loc_obj.master_campaign && loc_obj.master_campaign.length) {
							obj['data_source'] = loc_obj.master_campaign;
						} else {
							obj['data_source'] = 'others';
						}
						obj['agents_count'] = 0;
						if (agents_list && agents_list.length) {
							obj['agents_count'] = agents_list.length;
						}
						obj['agents_list'] = loc_obj.agents_list;
						obj['is_latest'] = false;
						obj['bulk_selected'] = false;
						arr.push(obj)

					}

					this.currentCamapigns = arr;
					// console.log('current campaigns', this.currentCamapigns)
					this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
					this.dataSourceCampaign.paginator = this.paginator;
					this.dataSourceCampaign.filterPredicate = function (data: any, filter_value: String) {
						return data.campaign_name.toLowerCase().includes(filter_value)
					}
					this.get_details_by_campaigns_loaded(null);
				}
			}, (err: any) => {
				this.showLoader = false;
			}
		)
	}

	get_paged_campaigns_from_table() {
		try {
			console.log('data source campaign', this.dataSourceCampaign)
			const skip = this.dataSourceCampaign.paginator.pageSize * this.dataSourceCampaign.paginator.pageIndex;
			return this.dataSourceCampaign.filteredData.filter((u, i) => i >= skip)
				.filter((u, i) => i < this.dataSourceCampaign.paginator.pageSize);
		} catch (e) {
			console.error(e);
			return [];
		}
	}

	get_filtered_campaigns_from_table(){
		try{
			return this.dataSourceCampaign.filteredData;
		}catch(e){
			console.error(e);
			return []
		}
	}

	get_details_by_campaigns_loaded(event: any) {
		const paged = this.get_paged_campaigns_from_table();
		// const table_data = this.dataSourceCampaign.data;
		paged.forEach(async (campaign: any) => {
			if (!campaign.is_latest) {
				await this.API.getGenericCampaigns({ campaign_id: campaign.id }, this.api_end_point).subscribe(
					(res: any) => {
						if (res.success) {
							var loc_obj = res.success;
							const campaignIndex = this.currentCamapigns.findIndex(x => x.id == loc_obj.id);
							this.currentCamapigns[campaignIndex]['contacted'] = loc_obj['data_contacted'];
							this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj['total_pushed'];
							this.currentCamapigns[campaignIndex]['data_added'] = loc_obj['total_assigned'];
							this.currentCamapigns[campaignIndex]['data_to_call'] = loc_obj['total_assigned'] - loc_obj['data_contacted'];
							this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj['total_pushed'] || 0;
							this.currentCamapigns[campaignIndex]['data_dialled'] = loc_obj['data_dialled'] || 0;
							this.currentCamapigns[campaignIndex]['data_duplicate'] = loc_obj['duplicate'] || 0;
							this.currentCamapigns[campaignIndex]['errors'] = loc_obj['errors'] || 0;
							this.currentCamapigns[campaignIndex]['is_latest'] = true;
						}
					}, (err: any) => {
						// this.showLoader = false;
					}
				)
			}
		})
		/* Check for the bulk campaign actions */
		if (this.check_visibility_bulk_div) {
			this.update_bulk_all_selected();
		}
	}

	openCreateCampaign() {
		// console.log('Creating a campaign');
		this.isCampbtnDisable = true;
		var dialogRef = this.dialog.open(CampaignDialogComponent, {
			minWidth: '60%',
			hasBackdrop: true,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				// alert('true');
				this.refreshCampaignData();
			}
			// console.log('The dialog was closed');
			this.isCampbtnDisable = false;
		});
	}

	downloadAttachment() {
		// console.log('Creating a campaign');
		this.isCampbtnDisable = true;
		var dialogRef = this.dialog.open(DownloadAttachmentComponent, {
			minWidth: '35%',
			hasBackdrop: true,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				// alert('true');
				this.refreshCampaignData();
			}
			// console.log('The dialog was closed');
			this.isCampbtnDisable = false;
		});
	}

	openCampaignDetails(obj) {
		this.dashService.SELECTED_CAMPAIGN.next(this.current_campaigns[obj.index_val]);
		this.show_details = true;
		let navigationExtras: NavigationExtras = {
			relativeTo: this.activatedRoute
		}
		//console.log('opening campaign details - route to another', obj, this.current_campaigns[obj.index_val]);

		this.router.navigate(['details'], navigationExtras);
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSourceCampaign.filter = filterValue;
		this.get_details_by_campaigns_loaded(null);
	}

	extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
				arr.push(obj[ref[i]])
			} else {
				arr.push('')
			}
		}
		return arr;
	}

	exportExcel(): void {
		// console.log('exporting excel');
		var data_mapper = {
			"name": "Name",
			"phone": "Phone",
			"Education": "Education Qualifications",
			"DOB": "Date of Birth",
			"Setup": "Laptop/Desktop",
			"Connectivity": "Dongle/BroadBand/Wifi/Hotspot",
			"City": "City",
			"State": "State",
			"Language": "English/ Hindi",
			"created_at": "Reg. Date",
			"current_status": "Status",
			"event_1_interview": "L1 Date",
			"event_1_organizer": "L1 Agent",
			"event_1_status": "L1 Status",
			"event_2_interview": "L2 Date",
			"event_2_organizer": "L2 Agent",
			"event_2_status": "L2 Status",
			"event_3_interview": "Client Interview Date",
			"event_3_status": "Client Interview Status",
			"training_start_date": "Training Start",
			"training_end_date": "Training End",
			"trainer_id": "Trainer",
			"master_campaign": "Agency Type",
			"campaign_agency": "Agency Name",
			"campaign_name": "Campaign Name"
		}
	}

	addmore(obj: any) {
		//console.log(this.API._selectClient)
		var data = obj;
		//console.log(JSON.stringify(data));
		/*var obj={
			forNew:"add",
			audit_client_id:this.API._selectClient.id
		}*/
		let _element = {
			"_element": data,
		}
		//'this.dataSource[0]'
		const dialogRef = this.dialog.open(DashboardAddmoreDialogComponent, {
			width: '50%',
			disableClose: true,
			data: _element
		});

		dialogRef.afterClosed().subscribe(result => {
			//console.log(result);
		});
	}

	AssignNow(val) {
		var data = val;
		//console.log(data);

		let uploaded_file = data.url.split('/');
		let file_name = uploaded_file.pop();

		let obj: any = {
			"blobfile": file_name,
			"start_date": data.pushed_date,
			"maxleadsperday": 500,
			"options": "Drop repeated data",
			"campaign_name": data.campaign_name,
			"questionnaire_form": data.questionnaire_form,//vk
			"priority": data.priority,
			"master_campaign": data.master_campaign,
			"incallscreen": data.incallscreen,
			"push_calltype": data.push_calltype,
			"groupedagentslist": JSON.parse(data.agents_list)
		}
		/*if (data.priority == 2) {
			obj.dial_ratio = this.pd_dial_ratio;
		}*/
		//console.log(obj);
		this.API.bulkPushAPI(obj, this.api_end_point).subscribe(
			(res: any) => {
				if (res.errors) {
					//console.log(res.errors);
					this._snackBar.open(res.errors, 'close');
				}

				this.refreshCampaignData();
				//console.log(res.success);
				//this.closePopup();
				this._snackBar.open('Assign Now', 'close');
				window.location.reload();

			})/**/

	}
	addagent(obj: any) {
		//console.log(this.API._selectClient)
		var data = obj;
		//console.log(JSON.stringify(data));

		data.dial_mode = 'normal'
		if (data.priority == 1) {
			data.dial_mode = 'server'
		}
		if (data.priority == 2) {
			data.dial_mode = 'predictive'
		}
		if (data.priority == 3) {
			data.dial_mode = 'ivr'
		}
		if (data.priority == 4) {
			data.dial_mode = 'divr'
		}
		let obj_topost = {
			'campaign': data.campaign,
			'center_id': data.user_id,
			'agents_list': data.agents_list,
			'priority': data.dial_mode
		}

		//debugger;
		this.API.getCampaignAgentsData(obj_topost).subscribe(
			(res: any) => {
				// debugger;
				if (res.error) {
					alert(res.error)
				}
				if (res.success) {
					//console.log('printing success', res.success);
					data.agentdata = res.success;

					let _element = {
						"_element": data,
					}
					//'this.dataSource[0]'
					const dialogRef = this.dialog.open(DashboardAddagentComponent, {
						width: '60%',
						disableClose: true,
						data: _element
					});

					dialogRef.afterClosed().subscribe(result => {
						//console.log(result);
					});
				}
			})


	}
	actionViewMore(element) {
		if (element.incallscreen &&
			!element.incallscreen.length &&
			element.user_id != this.user.id) {
			return;
		}
		this.myservice.clear_campaign_details()
		let obj = element;
		var data = obj;
		// console.log('campaign obj', JSON.stringify(data));
		let obj_topost = {
			'campaign': data.campaign,
			'center_id': [],
			'agents_list': data.agents_list,
			'priority': data.priority
		}
		var findgroupids = JSON.parse(obj.agents_list);
		var arrayofgroupid = Object.values(findgroupids);
		for (var e = 0; e < arrayofgroupid.length; e++) {
			var data: any;
			data = arrayofgroupid[e];
			//console.log(data);

			obj_topost.center_id.push(data[e].groupid);
		}
		this.API.getCampaignAgentsData(obj_topost, this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				if (res.error) {
					alert(res.error)
				}
				if (res.success) {
					//console.log('printing success', res.success);
					data.agentdata = res.success;
					// console.log(res.success);
					obj.campaign_agent_list = res.success;
					obj.pagename = "campaignV2";
					this.myservice.set_campaign_detail(obj);
				}
				this.router.navigateByUrl('/campaignV2/campaignDetails');

			})

	}
	createCampaignPage() {
		this.router.navigateByUrl('/campaignV2/campaign-central-create');
	}
	check_premission(obj) {
		//console.log(obj);
		return obj;
	}

	visibility_my_agents_report(obj) {
		let campaign_full_access = this.campaign_service.having_full_access()

		if ((obj.user_id != this.user.id) && !campaign_full_access) {
			return true
		}
		return false
	}
	/***************************** Bulk Campaigns Turn On/Off **************************/
	bulk_camapign_btn_click() {
		this.show_bulk_selection_banner = !this.show_bulk_selection_banner;		
		this.reset_bulk_selection_controls();
	}

	toggle_selection_box_bulk_selection(){
		if (this.show_bulk_selection_banner) {
			this.table_row_columns_action('insert')
		} else {
			this.table_row_columns_action('remove')
		}
	}

	update_bulk_selection_status_all_campaigns(status: boolean) {
		const page_campaigns = this.get_filtered_campaigns_from_table();
		if (page_campaigns.length) {
			page_campaigns.map((e: any) => {
				const campaignIndex = this.currentCamapigns.findIndex(x => x.id == e.id);
				this.currentCamapigns[campaignIndex]['bulk_selected'] = false;
				if (!this.check_bulk_campaign_disabled(e)) {
					this.currentCamapigns[campaignIndex]['bulk_selected'] = status;
				}
			})
		}
		this.update_bulk_all_selected()
	}

	table_row_columns_action(type) {
		if (type == 'insert') {
			if (this.displayedColumnsCampaigan.indexOf('bulk_selected') < 0) {
				this.displayedColumnsCampaigan.unshift('bulk_selected')
			}
		}
		if (type == 'remove') {
			if (this.displayedColumnsCampaigan.indexOf('bulk_selected') > -1) {
				this.displayedColumnsCampaigan.shift()
			}
		}
	}

	close_bulk_camapign_selection_banner() {
		this.show_bulk_selection_banner = false;
		this.reset_bulk_selection_controls();
	}

	reset_bulk_selection_controls() {
		// this.show_bulk_selection_banner = false;
		this.update_bulk_selection_status_all_campaigns(false);
		this.toggle_selection_box_bulk_selection();
	}

	bulk_all_campaigns_check_disabled(): boolean {
		const page_campaigns = this.get_filtered_campaigns_from_table();
		return page_campaigns.every((element, index, array) => {
			return this.check_bulk_campaign_disabled(element)
		})
	}

	bulk_assign_all_selected(): boolean{
		const page_campaigns = this.get_filtered_campaigns_from_table();
		const selected_campaigns = page_campaigns.filter((e:any) => e.bulk_selected);
		return page_campaigns.length == selected_campaigns.length;
	}

	count_campaigns_selected(){
		const page_campaigns = this.get_filtered_campaigns_from_table();
		const selected_campaigns = page_campaigns.filter((e:any) => e.bulk_selected);
		return selected_campaigns.length;
	}

	update_bulk_all_selected() {
		const page_campaigns = this.get_filtered_campaigns_from_table();
		this.bulk_all_campaigns_selected = page_campaigns.every((element: any, index, array) => {
			return element.bulk_selected;
		})
	}

	bulk_partial_camapigns_selected(): boolean {
		const page_campaigns = this.get_filtered_campaigns_from_table();
		return !this.bulk_all_campaigns_selected && page_campaigns.some((element: any, index, array) => {
			return element.bulk_selected;
		});
	}

	get check_visibility_bulk_div(): boolean {
		try {
			if (this.currentCamapigns.length > 1) {
				return true;
			}
			return false;
		} catch (e) {
			return false;
		}
	}

	bulk_campaigns_select_all(val) {
		this.update_bulk_selection_status_all_campaigns(val);
	}

	bulk_camapign_selected(campaign) {
		console.log('campaign', campaign);
		campaign.bulk_selected = !campaign.bulk_selected;
		this.update_bulk_all_selected();
	}

	check_bulk_campaign_disabled(campaign): boolean {
		if(this.campaign_service.disable_campaign_full_access(campaign)){
			return true;
		}
		if (this.bulk_camapigns_switch) {
			if (campaign.campaign_status) {
				return true;
			}
			return false;
		} else {
			if (campaign.campaign_status) {
				return false;
			}
			return true;
		}
	}

	send_bulk_action_campaigns(){
		var selected_campaigns = [];
		const page_campaigns = this.get_filtered_campaigns_from_table();
		page_campaigns.forEach((e:any) => {
			if(e.bulk_selected){
				selected_campaigns.push(e.campaign);
			}
		});
		if(selected_campaigns.length){
			this.showLoader = true;
			console.log('selected_campaigns', selected_campaigns, this.bulk_camapigns_switch);
			let obj = {};
			if(this.bulk_camapigns_switch){
				obj['active_list'] = selected_campaigns;
			}else{
				obj['inactive_list'] = selected_campaigns;
			}
			this.API.campaignSwitch(obj, this.api_end_point).subscribe((res: any) => {
				if (res.errors) {
					//	alert('Camapign Switch Failed');				
					this._snackBar.open('Bulk Campaigns Switch Failed', 'close', {duration: 2000});
				} else {
					//alert('Campaign Switch Success.');
					this._snackBar.open('Bulk Campaigns Switch Success', 'close', {duration: 2000});
				}
				this.refreshCampaignData();
			})
		}
	}
}

